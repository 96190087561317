//mycheftool
export const firebaseConfig = {
  apiKey: "AIzaSyDE2JcWG11DGfM2wqbVps4M9mfHLLYvsdk",
  authDomain: "mycheftool-d3899.firebaseapp.com",
  databaseURL: "https://mycheftool-d3899.firebaseio.com",
  projectId: "mycheftool-d3899",
  storageBucket: "mycheftool-d3899.appspot.com",
  messagingSenderId: "934198322702",
  appId: "1:934198322702:web:16cc4c33a689a004"
};

//mycheftool debug
/*
export const firebaseConfig = {
  apiKey: "AIzaSyCtplAgjeAW6cBLO9hQcFaNnhde6M3Zfj8",
  authDomain: "mycheftool-debug.firebaseapp.com",
  databaseURL: "https://mycheftool-debug.firebaseio.com",
  projectId: "mycheftool-debug",
  storageBucket: "mycheftool-debug.appspot.com",
  messagingSenderId: "150131355639",
  appId: "1:150131355639:web:650b8c66ee0cf49b"
};*/