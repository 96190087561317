import { Component, OnInit, Input } from '@angular/core';
import { GlobalsService } from '../../globals.service';
import { ModalController } from '@ionic/angular';
import { OrdersalesService } from '../../ordersales.service';
import { ModalImgElementComponent } from '../../modal-img-element/modal-img-element.component';
import { ModalSelectElementsComponent } from '../../modal-select-elements/modal-select-elements.component';

@Component({
  selector: 'app-carta-template-bultaco',
  templateUrl: './carta-template-bultaco.component.html',
  styleUrls: ['./carta-template-bultaco.component.scss'],
})
export class CartaTemplateBultacoComponent implements OnInit {

  @Input() section;
  @Input() carta;

  constructor(public globals: GlobalsService,
    private modalController: ModalController,
    public orderSalesService: OrdersalesService,) { }

  ngOnInit() {}

  async modalImgElement(img, type) {
    var source = '';
    if (type == 'img_element') {
      source = this.globals.getElementImg(img)
    } else {
      source = img;
    }
    const modal = await this.modalController.create({
      component: ModalImgElementComponent,
      componentProps: {
        'source': source,
      }
    });
    return await modal.present();
  }

  async addElement(element, origin, section_name) {
    var title = this.globals.returnNameByLang(element);
    if (element.type == 'menu') {
      title = element.name_es;
      origin = 'combo';
      element = this.globals.all_menus.find(e => e.UID == element.uid);
    }



    const modal = await this.modalController.create({
      component: ModalSelectElementsComponent,
      componentProps: {
        'element': element,
        'title': title,
        'origin': origin,
        'section_name': section_name,
        'lang': this.globals.lang,
      }
    });

    modal.onDidDismiss().then(v => {
      this.orderSalesService.calculateTotal();
      this.orderSalesService.calculateTotalWithoutTax();
      this.showOrder();
    });
    return await modal.present();
  }

  showOrder() {
    let order = document.getElementById('order_cesta');
    if (this.globals.order_cesta.length > 0) {
      order.style.display = 'block';
      order.classList.add("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");

    } else {
      order.style.display = 'none';
      order.classList.remove("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");
    }
  }

}
