import { Component, OnInit, Input } from '@angular/core';
import { GlobalsService } from '../../globals.service';

@Component({
  selector: 'app-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.scss'],
})
export class AllergensComponent implements OnInit {

  @Input() allergens;
  @Input() width;
  //width: string = '25';

  constructor(private globals: GlobalsService) { }

  ngOnInit() {
  }

  styleIcon() {

    let styles = {
      'width': this.width + 'px',
    };
    return styles;
  }

}
