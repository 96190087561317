import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalsService } from '../../globals.service';
import { ModalImgElementComponent } from '../../modal-img-element/modal-img-element.component';
import { ModalSelectElementsComponent } from '../../modal-select-elements/modal-select-elements.component';
import { OrdersalesService } from '../../ordersales.service';

@Component({
  selector: 'app-combo-template-bh',
  templateUrl: './combo-template-bh.component.html',
  styleUrls: ['./combo-template-bh.component.scss'],
})
export class ComboTemplateBhComponent implements OnInit {

  @Input() combo;
  @Input() section;
  
  constructor(public globals: GlobalsService,
    private modalController: ModalController,
    public orderSalesService: OrdersalesService,) { }

  ngOnInit() {}

  async modalImgElement(img, type) {
    var source = '';
    if (type == 'img_element') {
      source = this.globals.getElementImg(img)
    } else {
      source = img;
    }
    const modal = await this.modalController.create({
      component: ModalImgElementComponent,
      componentProps: {
        'source': source,
      }
    });
    return await modal.present();
  }

  showOrder() {
    let order = document.getElementById('order_cesta');
    if (this.globals.order_cesta.length > 0) {
      order.style.display = 'block';
      order.classList.add("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");

    } else {
      order.style.display = 'none';
      order.classList.remove("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");
    }
  }

  bgImg(img) {
    let _img = this.globals.getElementImg(img);
    let styles = {
      'background-image': 'url("' + _img + '")',
      'display': 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      'background-size': 'cover',
      //'height': '180px',
    }
    return styles;
  }

  showDesc(element){
    switch (this.globals.lang) {
      case 'es': {
        if(element.description_es){
          return true;
        }else{
          return false
        }
      }
      case 'ca': {
        if(element.description_ca){
          return true;
        }else{
          return false
        }
      }
      case 'en': {
        if(element.description_en){
          return true;
        }else{
          return false
        }
      }
      case 'de': {
        if(element.description_de){
          return true;
        }else{
          return false
        }
      }
      case 'fr': {
        if(element.description_fr){
          return true;
        }else{
          return false
        }
      }
      case 'it': {
        if(element.description_it){
          return true;
        }else{
          return false
        }
      }
      default: {
        if(element.description_es){
          return true;
        }else{
          return false
        }
      }
    }
  }

  async addMenuCombo(menu) {
    var origin = 'menu';
    if (menu.style == 'combo') {
      origin = 'combo';
    }

    const modal = await this.modalController.create({
      component: ModalSelectElementsComponent,
      componentProps: {
        'element': menu,
        'title': menu.title_es,
        'origin': origin,
        'lang': this.globals.lang,
      }
    });

    modal.onDidDismiss().then(v => {
      this.orderSalesService.calculateTotal();
      this.orderSalesService.calculateTotalWithoutTax();
      this.showOrder();
    });
    return await modal.present();
  }

}
