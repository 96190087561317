import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(public globals: GlobalsService,
    public afs: AngularFirestore) { }



  sendSalesSales(sales_sales) {
    this.afs.collection('_data').add({
      'uid': sales_sales.uid,
      'uid_doc': sales_sales.uid_doc,
      'date': moment(sales_sales.date).format('YYYY-MM-DD HH:mm:ss'),
      'sales': sales_sales.sales,
      'type': sales_sales.type,
      'type_type': sales_sales.type_type,
      'persons': sales_sales.persons,
      'zone': sales_sales.zone,
      'discount_type': sales_sales.discount_type,
      'discount': sales_sales.discount,
      'foodcost': sales_sales.foodcost,
      'app_section': sales_sales.app_section,
      'text': sales_sales.text
    });
  }

  sendSalesElements(sales_elements) {
    this.afs.collection('_data').add({
      'uid': sales_elements.uid,
      'uid_doc': sales_elements.uid_doc,
      'date': moment(sales_elements.date).format('YYYY-MM-DD HH:mm:ss'),
      'type': sales_elements.type,
      'list': sales_elements.list,
    });
  }

  sendSalesDelivery(sales_delivery) {
    this.afs.collection('_data').add({
      "uid": sales_delivery.uid,//uid_restaurant
      "uid_doc": sales_delivery.uid_doc,//uid del ticket
      "date": moment(sales_delivery.date).format('YYYY-MM-DD HH:mm:ss'),//date
      "sales": sales_delivery.sales,//amount
      "type": sales_delivery.type,//el type para el switch de cloud funtions 
      "address": sales_delivery.address,//la address del customer
      "delivery": sales_delivery.delivery//delivery utilizado
    });
  }

  sendStock(stock) {
    this.afs.collection('_data').add({
      "uid": stock.uid,//uid_restaurant
      "date": moment(stock.date).format('YYYY-MM-DD HH:mm:ss'),//date
      "type": stock.type,//el type para el switch de cloud funtions
      "comment": stock.comment, //comentario de la actualizacion de stock
      "type_type": stock.type_type, //de donde sale la modificacion ticket, stock, manual etc
      "elements": stock.elements, //Array con todos los elementos que se han modificado el stock
    });
  }
}
