import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-img-element',
  templateUrl: './modal-img-element.component.html',
  styleUrls: ['./modal-img-element.component.scss'],
})
export class ModalImgElementComponent implements OnInit {

  @Input() source: any;

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

}
