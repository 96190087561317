import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalsService } from '../globals.service';

@Component({
  selector: 'app-modal-allergens',
  templateUrl: './modal-allergens.component.html',
  styleUrls: ['./modal-allergens.component.scss'],
})
export class ModalAllergensComponent implements OnInit {

  @Input() lang: any;
  
  constructor(private modalController: ModalController, private globals: GlobalsService) { }

  ngOnInit() {}

  close(){
    this.modalController.dismiss();
  }

}
