import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalsService } from '../globals.service';
import { ModalAllergensComponent } from '../modal-allergens/modal-allergens.component';

@Component({
  selector: 'app-modal-select-elements',
  templateUrl: './modal-select-elements.component.html',
  styleUrls: ['./modal-select-elements.component.scss'],
})
export class ModalSelectElementsComponent implements OnInit {

  @Input() element: any;
  @Input() title: any;
  @Input() origin: any;
  @Input() lang: any;
  @Input() section_name: any;

  constructor(private modalController: ModalController, private globals: GlobalsService) { }

  ngOnInit() { }

  close() {
    this.modalController.dismiss();
  }

  async modalAllergens() {
    const modal = await this.modalController.create({
      component: ModalAllergensComponent,
      componentProps: {
        'lang': this.globals.lang,
      }
    });
    return await modal.present();
  }

}
