import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { HttpParams, HttpClient } from '@angular/common/http';
import { getCurrencySymbol } from '@angular/common';
import { AnalyticsService } from './analytics.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})

export class OrdersalesService {

  private firestore: any;
  ticket_num_return: any;
  customer: any = [];
  card: any = [];

  total: any;
  subtotal: any;
  sending_price: any;
  total_elements: any;

  constructor(public globals: GlobalsService,
    public afs: AngularFirestore,
    public http: HttpClient,
    public analyticsService: AnalyticsService,
    public notificationsServices: NotificationsService) {
    this.firestore = firebase.firestore();
  }


  //
  insertTable(_name, _price, _delete, _count, _type, _uid, sec, _uid_combo, _uid_combo_tmp, units, _type_combo, _tags) {
    if (_tags == undefined) {
      _tags = [];
    }
    //recoremos las unidades que necestimos
    for (var _i = 0; _i < units; _i++) {
      //
      if (_price == undefined) {
        _price = 0;
      }
      //revisar si el _uid_combo_tmp esta null
      if (_uid_combo_tmp != null) {
        _uid_combo_tmp++
      } else {
        _uid_combo_tmp = null;
      }
      //
      var obj;
      if (_type_combo == 'combo') {
        obj = {
          count: _count,
          delete: _delete,
          name: _name,
          price: _price,
          type: _type,
          uid_element: _uid,
          uid_element_combo: _uid_combo,
          uid_combo_tmp: _uid_combo_tmp,
          units: 1,
          change: '',
          finish: '',
          state: 'normal',
          variations: {},
          tags: _tags
        }
      } else {
        obj = {
          count: _count,
          delete: _delete,
          name: _name,
          price: _price,
          type: _type,
          uid_element: _uid,
          uid_element_combo: _uid_combo,
          units: 1,
          change: '',
          finish: '',
          state: 'normal',
          variations: {},
          tags: _tags
        }
      }
      //
      var _combo_section = true;
      if (_type_combo == 'combo') {
        _combo_section = false;
      } else if (_type_combo == 'element') {
        _combo_section = false;
      } else if (_type_combo == 'menu') {
        _combo_section = false;
      }
      //modificacio
      var section = {};
      //
      var index = this.globals.order_tmp.findIndex(x => x.name == sec);
      if (index >= 0) {
        //this.order_tmp[index].list.push(list);
        var last_key = Object.keys(this.globals.order_tmp[index].list)[Object.keys(this.globals.order_tmp[index].list).length - 1]; //get the last key in the object
        if (last_key == undefined) {
          last_key = '-1';//Iniciamos a 0 si esta undefined
        }
        this.globals.order_tmp[index].list[Number(last_key) + 1] = obj;
      } else {
        section = {
          name: sec,
          only_combo: _combo_section,
          list: {
            0: obj,
          },
        }
        this.globals.order_tmp.push(section);
      }
    }
  }

  //Funcion que inserta el precio de envio en el ticket
  insertSendingPriceTicket() {
    var section = {
      name: 'Envío',
      units: 1,
      price: this.sending_price,
    }
    return section;
  }

  orderCesta() {
    var _order_tmp_clean = [];
    for (let section of this.globals.order_tmp) {
      for (let element of this.globals.objectToArray(section.list)) {
        //le ponemos seccion simepre a los elementos indv
        element.section = section.name;
        //si es menu combo push si o si
        if (element.type == 'menu' || element.type == 'combo') {
          element.list = [];
          //recorremos otra vez la lista
          for (let _section of this.globals.order_tmp) {
            for (let _element of this.globals.objectToArray(_section.list)) {
              //añadimos los elementos del menu o combo dentro de una lista en el mismo 
              if (element.uid_combo_tmp == _element.uid_combo_tmp && (_element.type == 'dish' || _element.type == 'product')) {
                element.list.push(_element);
              }
            }
          }
          //ya que usarems units_tmp para calcular todo se lo añadimos al combo y menu
          element.units_tmp = element.units;
          _order_tmp_clean.push(element);
        } else {//dishes/prods
          //si uid_combo_tmp es null quiere decir que es un elemento de carta unimos por varias condiciones
          if (element.uid_combo_tmp == null) {
            var index = _order_tmp_clean.findIndex(item => item.uid_element == element.uid_element
              && item.price == element.price
              && item.count == element.count
              && item.delete == element.delete);
            if (index == -1) {
              //usamos este tmp definitivo para no modificar del array original las unidades
              element.units_tmp = 1;
              _order_tmp_clean.push(element);
            } else {
              _order_tmp_clean[index].units_tmp++;
            }
          }

        }
      }
    }
    //en la global pasamos los datos definitivos.
    this.globals.order_cesta = _order_tmp_clean;
  }

  //funcion que calcula el total de la comanda
  calculateTotal() {
    var total = 0;
    var total_elements = 0;
    for (let element of this.globals.order_cesta) {
      total += element.price * element.units_tmp;
      total_elements += element.units_tmp;
    }
    //return Math.round((total + Number.EPSILON) * 100) / 100;
    this.total = Math.round((total + Number.EPSILON) * 100) / 100;
    this.total_elements = total_elements;
    //Ponemos el precio de envio a 0
    this.sending_price = 0;
  }

  //funcion que calcula el total sin los impuestos de la comanda
  calculateTotalWithoutTax() {
    var total = this.total;
    var sub = 0;
    var sub = total / ((this.globals.restaurant_tax + 100) / 100);
    //return Math.round((sub + Number.EPSILON) * 100) / 100;
    this.subtotal = Math.round((sub + Number.EPSILON) * 100) / 100;
  }

  //funcion que calcula el total y el subtotal teniendo en cuenta el precio de envio
  calculateTotalAndSubtotalWithSending() {
    var total = this.total;
    var sub = 0;

    //comprocamos que no sea null para que no pete el total
    if (this.globals.restaurant.web_free_sending == null) {
      this.globals.restaurant.web_free_sending = 0;
    }
    if (this.globals.restaurant.web_sending_price == null) {
      this.globals.restaurant.web_sending_price = 0;
    }

    //Comprovamos si el envio gratis esta activado
    if (this.globals.restaurant.web_free_sending > 0) {
      //Si el envio gratis esta activado, comprovamos si el total es igual o superior al envio gratis
      if (total >= this.globals.restaurant.web_free_sending) {
        //Si el total es igual o superior al envio gratis, solo se tiene en cuenta el total del pedido y no el precio envio
        this.sending_price = 0;
        sub = total / ((this.globals.restaurant_tax + 100) / 100);
      } else {
        //Si el total es inferior al envio gratis, se suma el total y el precio envio
        this.sending_price = this.globals.restaurant.web_sending_price;
        total = total + this.globals.restaurant.web_sending_price;
        sub = total;
        sub = sub / ((this.globals.restaurant_tax + 100) / 100);
      }
    } else {
      //Si el envio gratis esta desactivado siempre se suma el precio envio
      this.sending_price = this.globals.restaurant.web_sending_price;
      total = total + this.globals.restaurant.web_sending_price;
      sub = total;
      sub = sub / ((this.globals.restaurant_tax + 100) / 100);
    }

    this.total = Math.round((total + Number.EPSILON) * 100) / 100;
    this.subtotal = Math.round((sub + Number.EPSILON) * 100) / 100;
  }

  //funcion que calcula el total de elementos en la comanda
  calculateTotalElements() {
    var total = 0;
    for (let element of this.globals.order_cesta) {
      total += element.units_tmp
    }
    return total;
  }

  //funcion que elimina elementos
  removeElement(element) {
    var count = 1;

    for (let index_section in this.globals.order_tmp) {
      if (this.globals.order_tmp[index_section].name == element.section) {
        for (let index_element in this.globals.objectToArray(this.globals.order_tmp[index_section].list)) {

          if (this.globals.order_tmp[index_section].list[index_element].type == 'menu' || this.globals.order_tmp[index_section].list[index_element].type == 'combo') {
            if (count == 1) {
              //eliminar elementos del combo o menu
              this.deleteElementsOfCombosMenus(element);
              //delete this.order_tmp[index_section].list[index_element];
              count--;
            }
          } else {//elementos
            //mirar que coincida en varios campos
            if (this.globals.order_tmp[index_section].list[index_element].uid == element.uid
              && this.globals.order_tmp[index_section].list[index_element].price == element.price
              && this.globals.order_tmp[index_section].list[index_element].count == element.count
              && this.globals.order_tmp[index_section].list[index_element].delete == element.delete) {
              //
              if (count == 1) {
                delete this.globals.order_tmp[index_section].list[index_element];
                count--;
              }
            }
          }
        }

        this.reorderListIndex(index_section);
      }

    }
    this.orderCesta();
    this.calculateTotal();
    this.calculateTotalWithoutTax();
  }

  //
  reorderListIndex(index_section) {
    var new_list = [];

    for (let element of this.globals.objectToArray(this.globals.order_tmp[index_section].list)) {
      new_list.push(element);
    }
    this.globals.order_tmp[index_section].list = new_list;

  }

  //
  deleteElementsOfCombosMenus(cesta_element) {
    var order_tmp = this.globals.objectToArray(this.globals.order_tmp);
    for (let s_key in order_tmp) {
      var _list = this.globals.objectToArray(order_tmp[s_key].list);
      for (let e_key in _list) {
        if (cesta_element.uid_combo_tmp == order_tmp[s_key].list[e_key].uid_combo_tmp) {
          delete this.globals.order_tmp[s_key].list[Number(e_key)];
        }
      }
      this.reorderListIndex(s_key);
    }
    //this.reorderSectionIndex();
    this.orderCesta();
    this.calculateTotal();
    this.calculateTotalWithoutTax();
  }


  /* deleteEmptySections() {
     for (let s_key in this.globals.order_tmp) {
       var _list = this.globals.objectToArray(this.globals.order_tmp[s_key].list);
       if (_list.length == 0) {
         delete this.globals.order_tmp[s_key];
         
       }
     }
     //this.reorderSectionIndex();
   }*/

  reorderSectionIndex() {

    var new_sections = [];

    for (let section of this.globals.objectToArray(this.globals.order_tmp.sections)) {
      new_sections.push(section);
    }
    this.globals.order_tmp.sections = new_sections;

  }

  creatListForTicket() {
    var _order_tmp_clean = [];
    for (let section of this.globals.order_tmp) {
      for (let element of this.globals.objectToArray(section.list)) {
        _order_tmp_clean.push(element);
      }
    }
    return _order_tmp_clean;
  }

  creatListForTicketTable() {
    var _order_tmp_clean = [];
    for (let section of this.globals.order_tmp) {
      for (let element of this.globals.objectToArray(section.list)) {
        if ((element.type == 'combo' && element.uid_combo_tmp != undefined) || (element.type != 'combo' && element.uid_combo_tmp == undefined)) {
          _order_tmp_clean.push(element);
        }
      }
    }
    return _order_tmp_clean;
  }




  /**UNA VEZ HECHO EL PAGO**/
  //create TK caso  (domicilio / buscar local)

  //crear tk
  async createTakeaway(type, pi_id) {
    //recuperar cajas
    var cashs = await this.globals.getCashFirst();
    //crear la lista para order
    var list = this.creatListForTicket();
    //crear la lista para ticket y table
    var list_ticket_table = this.creatListForTicketTable();
    //recuperar amount
    var _amount = this.total;
    //Restamos stock
    this.deductStock(list);
    //get ticket number
    await this.getTicketNumber(cashs[0].UID);
    //guardamos el tipo de delivery
    let delivery;
    if (type == 'domicilio') {
      delivery = 'Domicilio';
    } else if (type == 'recoger') {
      delivery = 'Recogida';
    }
    //crear mesa
    this.afs.collection('tables').add({
      'UID_RESTAURANT': this.globals.restaurant_uid,
      'cash_static': cashs[0].name,
      'cash_uid': cashs[0].UID,
      'name': '',
      'persons': '',
      'size': 2,
      'state': 'closed',
      'ticket': {
        'amount': _amount,
        'number': this.ticket_num_return,
        'payment': true,
        'payment_type': 'card',
        'list': list_ticket_table,
        'date': new Date(),
        'waiter_uid': '',
        'waiter': '',
        'delivery': delivery,
        'created_at': new Date(),
        'updated_at': new Date(),
      },
      'type': 'takeaway',
      'uid_room': '',
      'customer_name': this.customer.name,
      'customer_address': this.customer.address,
      'customer_phone': this.customer.phone,
      'customer_email': this.customer.email,
      'customer_uid': this.customer.uid,
      'created_at': new Date(),
      'notes': this.customer.notes,
      'datetime_pickup_delivey': "",
      'order_state': 'payment_completed',


    }).then(async doc_table => {
      //create ticket
      this.afs.collection('tickets').add({
        UID_RESTAURANT: this.globals.restaurant_uid,
        amount: _amount,
        tax: this.globals.restaurant_tax,
        cash_static: cashs[0].name,
        cash_uid: cashs[0].UID,
        date: new Date(),
        list: list_ticket_table,
        number: this.ticket_num_return,
        payment: true,
        payment_type: 'card',
        waiter: '',
        waiter_uid: '',
        type: 'takeaway',
        closed: false,
        table_uid: doc_table.id,
        pending: false,
        discount_type: '',
        discount_value: '',
        tip: 0,
        //customer: this.customer,
        customer_uid: this.customer.uid,
        delivery: delivery,
        order_state: 'payment_completed',
        delivery_notes: this.customer.notes,

      }).then(doc_ticket => {
        this.afs.collection('tickets/' + doc_ticket.id + '/customer').add({
          'name': this.customer.name,
          'uid': this.customer.uid,
          'email': this.customer.email,
          'address': this.customer.address,
          'business_name': '',
          'business_number': '',
          'note': this.customer.notes,
          'phone': this.customer.phone,
        });
        /*this.afs.collection('restaurants/' + this.globals.restaurant_uid + '/charges').doc(charge_id).update({
          'ticket_number': this.ticket_num_return,
          'uid_ticket': doc_ticket.id,
        })*/
        //guardar ticket en paymentIntent
        //this.savePaymentIntentInCharges(pi_id, doc_ticket.id, this.ticket_num_return);
        //enviar por mail el ticket
        //this.getTicketAndSendEmail(doc_ticket.id, type);

        //preparamos el objeto que enviaremos a sales_sales(_data)
        var sales_sales = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "sales": _amount,//amount
          "type": "sales_sales",//el type para el switch de cloud funtions 
          "type_type": 'card',//el tipo de pagamiento que an usado
          "persons": 1,//la cantidad de personas
          "zone": "Takeaway",//la room
          "discount_type": null,//
          "discount": null,//
          "foodcost": 0,//foodcost
          'app_section': "sites",
          'text': this.ticket_num_return
        };
        //insert _data
        this.analyticsService.sendSalesSales(sales_sales);

        let elements = [];

        for (let element of this.globals.objectToArray(list_ticket_table)) {
          //comprobamos que el elemento no esté eliminado
          if (!element.delete) {
            var _element = {
              "name": element.name,//nom del producto/plato/menu
              "uid_element": element.uid_element,//uid el producto/plato/menu
              "sales": element.price,//precio 
              "type": element.type,//que tipo de elemento es producto/plato/menu
              "foodcost": 0,//foodcost
            };
            elements.push(_element)
          }
        }
        var sales_elements = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "list": elements,
          "type": "sales_elements",
        };
        console.log(sales_elements)
        //insert _data
        this.analyticsService.sendSalesElements(sales_elements);


        var sales_delivery = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "sales": _amount,//amount
          "type": "sales_delivery",//el type para el switch de cloud funtions 
          "address": this.customer.address,//la address del users
          "delivery": "" //delivery utilizado
        };
        //insert _data
        this.analyticsService.sendSalesDelivery(sales_delivery);

        //STOCK
        var stock = {
          "elements": elements,
          "uid": this.globals.restaurant_uid,
          "date": new Date(),
          "type": "stock",
          "comment": "",
          "type_type": "TICKET",
        };
        //insert _data
        this.analyticsService.sendStock(stock);

      });
      //create order
      await this.afs.collection('orders').add({
        'UID_RESTAURANT': this.globals.restaurant_uid,
        'date': new Date(),
        'hour': moment(new Date()).format("HH:mm"),
        'sections': this.globals.order_tmp,
        'show_kitchen': true,
        'show_order': true,
        'table': '',
        'table_uid': doc_table.id,
        'type': 'takeaway',
        'waiter': '',
        'waiter_uid': '',
        'created_at': new Date(),
        'updated_at': new Date(),
        'provider': 'General',
        'provider_code': '',
        'notes': this.customer.notes,
        'delivery': delivery,
        'ticket_number': this.ticket_num_return

      }).then(doc_order => {
      });
    });
  }

  async createTakeawayWithHour(type, pi_id, hour) {
    //recuperar cajas
    var cashs = await this.globals.getCashFirst();
    //crear la lista para order
    var list = this.creatListForTicket();
    //crear la lista para ticket
    var list_ticket_table = this.creatListForTicketTable();
    //recuperar amount
    var _amount = this.total;
    //Restamos stock
    this.deductStock(list);
    //get ticket number
    await this.getTicketNumber(cashs[0].UID);
    //guardamos el tipo de delivery
    let delivery;
    if (type == 'domicilio') {
      delivery = 'Domicilio';
    } else if (type == 'recoger') {
      delivery = 'Recogida';
    }
    //crear mesa
    this.afs.collection('tables').add({
      'UID_RESTAURANT': this.globals.restaurant_uid,
      'cash_static': cashs[0].name,
      'cash_uid': cashs[0].UID,
      'name': '',
      'persons': '',
      'size': 2,
      'state': 'closed',
      'ticket': {
        'amount': _amount,
        'number': this.ticket_num_return,
        'payment': true,
        'payment_type': 'card',
        'list': list_ticket_table,
        'date': new Date(),
        'waiter_uid': '',
        'waiter': '',
        'delivery': delivery,
        'created_at': new Date(),
        'updated_at': new Date(),
      },
      'type': 'takeaway',
      'uid_room': '',
      'customer_name': this.customer.name,
      'customer_address': this.customer.address,
      'customer_phone': this.customer.phone,
      'customer_email': this.customer.email,
      'customer_uid': this.customer.uid,
      'created_at': new Date(),
      'notes': this.customer.notes,
      'datetime_pickup_delivey': hour,
      'order_state': 'payment_completed',


    }).then(async doc_table => {
      //create ticket
      this.afs.collection('tickets').add({
        UID_RESTAURANT: this.globals.restaurant_uid,
        amount: _amount,
        tax: this.globals.restaurant_tax,
        cash_static: cashs[0].name,
        cash_uid: cashs[0].UID,
        date: new Date(),
        list: list_ticket_table,
        number: this.ticket_num_return,
        payment: true,
        payment_type: 'card',
        waiter: '',
        waiter_uid: '',
        type: 'takeaway',
        closed: false,
        table_uid: doc_table.id,
        pending: false,
        discount_type: '',
        discount_value: '',
        tip: 0,
        //customer: this.customer,
        customer_uid: this.customer.uid,
        delivery: delivery,
        order_state: 'payment_completed',
        datetime_pickup_delivey: hour,
        delivery_notes: this.customer.notes,

      }).then(doc_ticket => {
        this.afs.collection('tickets/' + doc_ticket.id + '/customer').add({
          'name': this.customer.name,
          'uid': this.customer.uid,
          'email': this.customer.email,
          'address': this.customer.address,
          'business_name': '',
          'business_number': '',
          'note': this.customer.notes,
          'phone': this.customer.phone,
          'datetime_pickup_delivey': hour,
        });
        /*this.afs.collection('restaurants/' + this.globals.restaurant_uid + '/charges').doc(charge_id).update({
          'ticket_number': this.ticket_num_return,
          'uid_ticket': doc_ticket.id,
        })*/
        //guardar ticket en paymentIntent
        //this.savePaymentIntentInCharges(pi_id, doc_ticket.id, this.ticket_num_return);
        //enviar por mail el ticket
        //this.getTicketAndSendEmail(doc_ticket.id, type);


        //preparamos el objeto que enviaremos a sales_sales(_data)
        var sales_sales = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "sales": _amount,//amount
          "type": "sales_sales",//el type para el switch de cloud funtions 
          "type_type": 'card',//el tipo de pagamiento que an usado
          "persons": 1,//la cantidad de personas
          "zone": "Takeaway",//la room
          "discount_type": null,//
          "discount": null,//
          "foodcost": 0,//foodcost
          'app_section': "sites",
          'text': this.ticket_num_return
        };
        //insert _data
        this.analyticsService.sendSalesSales(sales_sales);

        let elements = [];

        for (let element of this.globals.objectToArray(list_ticket_table)) {
          //comprobamos que el elemento no esté eliminado
          if (!element.delete) {
            var _element = {
              "name": element.name,//nom del producto/plato/menu
              "uid_element": element.uid_element,//uid el producto/plato/menu
              "sales": element.price,//precio 
              "type": element.type,//que tipo de elemento es producto/plato/menu
              "foodcost": 0,//foodcost
            };
            elements.push(_element)
          }
        }
        var sales_elements = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "list": elements,
          "type": "sales_elements",
        };
        console.log(sales_elements)
        //insert _data
        this.analyticsService.sendSalesElements(sales_elements);


        var sales_delivery = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "sales": _amount,//amount
          "type": "sales_delivery",//el type para el switch de cloud funtions 
          "address": this.customer.address,//la address del users
          "delivery": "" //delivery utilizado
        };
        //insert _data
        this.analyticsService.sendSalesDelivery(sales_delivery);

        //STOCK
        var stock = {
          "elements": elements,
          "uid": this.globals.restaurant_uid,
          "date": new Date(),
          "type": "stock",
          "comment": "",
          "type_type": "TICKET",
        };
        //insert _data
        this.analyticsService.sendStock(stock);

      });
      //create order
      await this.afs.collection('orders').add({
        'UID_RESTAURANT': this.globals.restaurant_uid,
        'date': new Date(),
        'hour': moment(new Date()).format("HH:mm"),
        'sections': this.globals.order_tmp,
        'show_kitchen': true,
        'show_order': true,
        'table': '',
        'table_uid': doc_table.id,
        'type': 'takeaway',
        'waiter': '',
        'waiter_uid': '',
        'created_at': new Date(),
        'updated_at': new Date(),
        'provider': 'General',
        'provider_code': '',
        'notes': this.customer.notes,
        'delivery': delivery,
        'datetime_pickup_delivey': hour,
        'ticket_number': this.ticket_num_return

      }).then(doc_order => {
      });
    });
  }

  //caso en mesa (crear ticket)
  async createTableSale(table_uid, type, pi_id) {
    //recuperar cajas
    var table = await this.getTable(table_uid);
    //limpiamos mesa
    await this.cleanTableForUse(table_uid);
    //crear la lista para order
    var list = this.creatListForTicket();
    //crear la lista para ticket
    var list_ticket_table = this.creatListForTicketTable();
    //recuperar amount
    var _amount = this.total;
    //Restamos stock
    this.deductStock(list);
    //get ticket number
    await this.getTicketNumber(table.cash_uid);
    //update mesa
    this.afs.collection('tables').doc(table_uid).update({
      'state': 'paid',
      'ticket': {
        'amount': _amount,
        'number': this.ticket_num_return,
        'payment': true,
        'payment_type': 'card',
        'list': list_ticket_table,
        'date': new Date(),
        'waiter_uid': '',
        'waiter': '',
        'delivery': '',
        'created_at': new Date(),
        'updated_at': new Date(),
      },
    }).then(async () => {
      //create ticket
      this.afs.collection('tickets').add({
        UID_RESTAURANT: this.globals.restaurant_uid,
        amount: _amount,
        tax: this.globals.restaurant_tax,
        cash_static: table.cash_static,
        cash_uid: table.cash_uid,
        date: new Date(),
        list: list_ticket_table,
        number: this.ticket_num_return,
        payment: true,
        payment_type: 'card',
        waiter: '',
        waiter_uid: '',
        type: 'table',
        closed: false,
        table_uid: table.UID,
        pending: false,
        discount_type: '',
        discount_value: '',
        tip: 0,
        customer: {},
        delivery: '',
        delivery_notes: '',

      }).then(doc_ticket => {
        /* this.afs.collection('restaurants/' + this.globals.restaurant_uid + '/charges').doc(charge_id).update({
           'ticket_number': this.ticket_num_return,
           'uid_ticket': doc_ticket.id,
         })*/

        //guardar ticket en paymentIntent
        //this.savePaymentIntentInCharges(pi_id, doc_ticket.id, this.ticket_num_return);
        //enviar por mail el ticket
        //this.getTicketAndSendEmail(doc_ticket.id, type);


        if (table.room == undefined || table.room == null) {
          table.room = "";
        }
        //preparamos el objeto que enviaremos a sales_sales(_data)
        var sales_sales = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "sales": _amount,//amount
          "type": "sales_sales",//el type para el switch de cloud funtions 
          "type_type": 'card',//el tipo de pagamiento que an usado
          "persons": 1,//la cantidad de personas
          "zone": table.room,//la room
          "discount_type": null,//
          "discount": null,//
          "foodcost": 0,//foodcost
          'app_section': "sites",
          'text': this.ticket_num_return
        };
        //insert _data
        this.analyticsService.sendSalesSales(sales_sales);

        let elements = [];

        for (let element of this.globals.objectToArray(list_ticket_table)) {
          //comprobamos que el elemento no esté eliminado
          if (!element.delete) {
            var _element = {
              "name": element.name,//nom del producto/plato/menu
              "uid_element": element.uid_element,//uid el producto/plato/menu
              "sales": element.price,//precio 
              "type": element.type,//que tipo de elemento es producto/plato/menu
              "foodcost": 0,//foodcost
            };
            elements.push(_element)
          }
        }
        var sales_elements = {
          "uid": this.globals.restaurant_uid,//uid_restaurant
          "uid_doc": doc_ticket.id,//uid del ticket
          "date": new Date(),//date
          "list": elements,
          "type": "sales_elements",
        };
        console.log(sales_elements)
        //insert _data
        this.analyticsService.sendSalesElements(sales_elements);

        //STOCK
        var stock = {
          "elements": elements,
          "uid": this.globals.restaurant_uid,
          "date": new Date(),
          "type": "stock",
          "comment": "",
          "type_type": "TICKET",
        };
        //insert _data
        this.analyticsService.sendStock(stock);


      });
      //create order
      await this.afs.collection('orders').add({
        'UID_RESTAURANT': this.globals.restaurant_uid,
        'date': new Date(),
        'hour': moment(new Date()).format("HH:mm"),
        'sections': this.globals.order_tmp,
        'show_kitchen': true,
        'show_order': true,
        'table': table.name,
        'table_uid': table.UID,
        'type': 'table',
        'waiter': '',
        'waiter_uid': '',
        'created_at': new Date(),
        'updated_at': new Date(),
        'provider': 'General',
        'provider_code': '',
        'ticket_number': this.ticket_num_return

      }).then(doc_order => {
      });
    });

  }


  //recuperar n ticket
  async getTicketNumber(cash_uid) {

    var _ticket_num_return = -1;

    var col = this.firestore.collection('cashs').doc(cash_uid);
    await col.get()
      .then(async doc => {
        var doc = doc.data()
        _ticket_num_return = doc.number + 1;
        this.ticket_num_return = _ticket_num_return;
        await this.cashUpdateNumber(cash_uid, _ticket_num_return);
      });

    return true;
  }

  //actualizar n ticket
  async cashUpdateNumber(uid, number) {
    //Actualizamos la tabla de la base de datos
    await this.afs.collection('cashs').doc(uid).update({
      number: number,
    });
  }

  //FUNCION RESTAR STOCK
  async deductStock(list) {
    //recorer lista de platos y productos
    for (let key in list) {
      var _type = list[key].type;
      var _uid = list[key].uid_element;
      //recuperar plato o producto y llamar funcion calculos
      if (list[key].units > 0) { //miramos por units mayor a 0
        //VARIACIONS
        if (list[key].variation) {
          for (let variation of list[key].variation) {//recorrer variaciones
            //estado sea positivo para poder restar stock -/- estado negativo no restamos stock -/- not specified no hacen nada
            if (variation.state === '+' && variation.type !== 'not specified') {
              var _variation = await this.getVariation(variation.uid);//recuperar variacio
              if (_variation.type == 'dish') {//dish
                var dish = await this.getDish(_variation.uid_element);//get dish
                for (let dish_key in dish.foodcost) {//recorrer dish
                  this.updateDeductedStock('dish', dish.foodcost[dish_key], dish.foodcost_parts, 'divide', this.getPercentOfQuantity(_variation.quantity), 1);
                }
              } else if (_variation.type == 'ingredient') {//ingredient
                var ingredient = await this.getIngredient(_variation.uid_element);//get ingredient
                if (!ingredient.sub_recipe) {
                  var _stock_ing = this.calculateMeasurements(ingredient.measurement, parseFloat(ingredient.stock), parseFloat(_variation.quantity), 1)//calculate stock
                  //update
                  await this.afs.collection('ingredients').doc(ingredient.UID).update({
                    'stock': this.checkIfStockNegative(_stock_ing),
                  });
                  //Comprovamos si el stock actual del ingrediente, es igual o menor a alerta_stock, para enviar notificacion
                  this.notifMinStock(_stock_ing, ingredient.stock_alert, ingredient.name);
                }
              } else {//product
                var product = await this.getProduct(_variation.uid_element);//get product
                if (product.sub_recipe) {//tiene foodcost
                  this.updateDeductedStock('product', product, product.foodcost_parts, 'multiply', parseFloat(_variation.quantity), 1);
                } else {//basic
                  var _stock_prod = this.calculateVariationProduct(parseFloat(product.stock), parseFloat(_variation.quantity))//calculate stock
                  //update
                  await this.afs.collection('products').doc(product.UID).update({
                    'stock': this.checkIfStockNegative(_stock_prod),
                  });
                  //Comprovamos si el stock actual del ingrediente, es igual o menor a alerta_stock, para enviar notificacion
                  this.notifMinStock(_stock_prod, product.stock_alert, this.globals.nameProductLangSelect(this.globals.restaurant.lang, product));
                }
              }
            }
          }
        }
        //ELEMENTS -> mirar como multiplicar las units
        if (_type == 'dish') {
          var element_dish = await this.getDish(_uid);//recuperar el plato entero
          for (let fc_key in element_dish.foodcost) {//recorrer foodcost del plato
            await this.updateDeductedStock(_type, element_dish.foodcost[fc_key], element_dish.foodcost_parts, 'divide', 1, list[key].units);//funcion que calcula el stock
          }
        } else if (_type == 'product') {
          var element_product = await this.getProduct(_uid);//recuperar producto entero
          element_product.uid = _uid;
          await this.updateDeductedStock(_type, element_product, element_product.foodcost_parts, 'divide', 1, list[key].units);//funcion que calcula el stock
        }
      }
    }
  }

  //calcular stocks(llamar funcion) y hacer update en bbdd
  async updateDeductedStock(type, proding, foodcost_parts, operation, number, units) {
    //DISH
    if (type == 'dish') {
      var dish_ingredient = await this.getIngredient(proding.uid);//recupera ingrediente
      if (!dish_ingredient.sub_recipe) {
        //calcula stock
        var _stock = this.calculateMeasurements(dish_ingredient.measurement, parseFloat(dish_ingredient.stock), this.divideQuantityFoodcostparts(foodcost_parts, proding.quantity_gross), units);
        if (operation == 'divide') {//variacion que es plato para dividir entre cantidad (1, 1/2, 1/4)
          _stock = _stock / number;
        } else {//caso normal elemento
          _stock = _stock - number;
        }
        //update
        await this.afs.collection('ingredients').doc(proding.uid).update({
          'stock': this.checkIfStockNegative(_stock),
        });
        //Comprovamos si el stock actual del ingrediente, es igual o menor a alerta_stock, para enviar notificacion
        this.notifMinStock(_stock, dish_ingredient.stock_alert, dish_ingredient.name);
      }
      //PRODUCT
    } else if (type == 'product') {
      if (proding.sub_recipe) {//producto con foodcost
        for (let proing of proding.foodcost) {//recorre ingredientes del foodcost
          var ingredient = await this.getIngredient(proing.uid);//recupera ingrediente entero
          if (!ingredient.sub_recipe) {
            var quantity_minus = 0;
            if (operation == 'multiply') {//caso de variacion multiplica por numero de unidades
              quantity_minus = this.divideQuantityFoodcostparts(foodcost_parts, proing.quantity_gross) * number;
            } else {//caso normal elemento
              quantity_minus = this.divideQuantityFoodcostparts(foodcost_parts, proing.quantity_gross);
            }
            var _stock_ing = this.calculateMeasurements(ingredient.measurement, parseFloat(ingredient.stock), quantity_minus, units);//calculo stock
            //update
            await this.afs.collection('ingredients').doc(proing.uid).update({
              'stock': this.checkIfStockNegative(_stock_ing),
            });
            //Comprovamos si el stock actual del ingrediente, es igual o menor a alerta_stock, para enviar notificacion
            this.notifMinStock(_stock_ing, ingredient.stock_alert, ingredient.name);
          }

        }
      } else {//producto basico
        //resta ud.
        var _stock_prod = this.calculateMeasurements(proding.measurement, parseFloat(proding.stock), this.divideQuantityFoodcostparts(foodcost_parts, proding.quantity_gross), units);
        //update
        await this.afs.collection('products').doc(proding.uid).update({
          'stock': _stock_prod,
        });
        //Comprovamos si el stock actual del ingrediente, es igual o menor a alerta_stock, para enviar notificacion
        this.notifMinStock(_stock_prod, proding.stock_alert, this.globals.nameProductLangSelect(this.globals.restaurant.lang, proding));
      }
    }
  }

  //calcula dependiendo de la medida (kg, l, ud.)
  calculateMeasurements(measurement, stock, minus_stock, units) {
    //multiplicar minus_stock(lo que se resta) por las units(unidades de ese elemento)
    var return_stock = 0;
    if (measurement === 'kg' || measurement === 'l') {
      //kg l
      var stock_grams_ml = stock * 1000;
      var total = stock_grams_ml - (minus_stock * units);
      return_stock = total / 1000;
    } else if (measurement === 'ud.') {
      return_stock = stock - (1 * units);
    } else {
      return_stock = parseFloat(stock) - (parseFloat(minus_stock) * units);
    }
    return this.checkIfStockNegative(return_stock.toFixed(2));
  }

  //calcula variacion ud
  calculateVariationProduct(stock, minus_stock) {
    var return_stock = 0;
    return_stock = stock - minus_stock;

    return this.checkIfStockNegative(return_stock.toFixed(2));
  }

  //retorna la quantitat de una/1 racion
  divideQuantityFoodcostparts(parts, quantity) {
    return parseFloat(quantity) / parseFloat(parts);
  }

  //funcion que pasa variacion plato y devuelve por cuanto hay que dividir
  getPercentOfQuantity(quantity) {
    if (quantity == '1') {
      return 1;
    } else if (quantity == '1/2') {
      return 0.5;
    } else {
      return 0.25;
    }
  }

  //funcion que mira si el numero pasado es negativo(siempre devuelve numero o 0)
  checkIfStockNegative(stock) {
    if (stock >= 0) {
      return parseFloat(stock);
    } else {
      return 0;
    }
  }

  async IFCustomerExistsOrCreateCustomer() {
    var customer_uid = '';
    var _customer = await this.checkInfoIFCustomerExists(this.customer.email, this.customer.phone);

    if (_customer) {
      this.customer.uid = _customer.UID;
      await this.afs.collection('customers').doc(_customer.UID).update({
        address: this.customer.address,
      });

    } else {
      this.afs.collection('customers').add({
        'UID_RESTAURANT': this.globals.restaurant_uid,
        'name': this.customer.name,
        'business_name': this.customer.name,
        'business_number': '',
        'email': this.customer.email,
        'phone': this.customer.phone,
        'address': this.customer.address,
        'note': this.customer.notes,
        'created_at': new Date()
      }).then(async doc => {
        this.customer.uid = await doc.id;
      });
    }
  }


  //funcion que mira si email/telefono existe el customer
  async checkInfoIFCustomerExists(email, phone) {
    var customers = await this.getCustomers(this.globals.restaurant_uid);
    var _return = undefined;

    //check phone
    _phone = undefined;
    if (phone) {
      var _phone = customers.find(e => e.phone === phone);
      if (_phone) {
        _return = _phone;
      }
    }
    //check email
    _email = undefined;
    if (email) {
      var _email = customers.find(e => e.email === email);
      if (_email) {
        _return = _email;
      }
    }

    return _return;
  }


  /* GETS FROM FIREBASE */

  async getDish(uid) {
    const dishRef = this.firestore.collection('dish').doc(uid);

    var _dish;

    await dishRef.get().then(doc => {
      _dish = doc.data();
      _dish.UID = doc.id;
    });

    return _dish

  }

  async getVariation(UID) {
    const varRef = this.firestore.collection('variations').doc(UID);
    var _variation;

    await varRef.get().then(doc => {
      _variation = doc.data();
      _variation.UID = doc.id;
    });

    return _variation
  }

  async getIngredient(uid) {
    const ingRef = this.firestore.collection('ingredients').doc(uid);

    var _ingr;

    await ingRef.get().then(doc => {
      _ingr = doc.data();
      _ingr.UID = doc.id;
    })

    return _ingr

  }

  async getProduct(uid) {
    const ingProd = this.firestore.collection('products').doc(uid);

    var _prod;

    await ingProd.get().then(doc => {
      _prod = doc.data();
      _prod.UID = doc.id;
    });

    return _prod

  }

  async getCustomers(UID_RESTAURANT) {
    const restRef = this.firestore.collection('customers');
    var customers = [];

    await restRef.where('UID_RESTAURANT', '==', UID_RESTAURANT).get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          var customer = doc.data();
          customer.UID = doc.id;
          customers.push(customer);
        });
      });

    return customers;
  }

  async getTable(uid) {
    const tableRef = this.firestore.collection('tables').doc(uid);

    var table;

    await tableRef.get().then(doc => {
      table = doc.data();
      table.UID = doc.id;
    });

    return table

  }

  async cleanTableForUse(table_uid) {
    await this.afs.collection('tables').doc(table_uid).update({
      'ticket': {
        'amount': 0,
        'payment': false,
        'payment_type': '',
        'date': '',
        'number': '',
        'waiter': '',
        'waiter_uid': '',
        'list': {},
        'created_at': new Date(),
        'updated_at': new Date(),
      },
      'state': 'open',
      'discount_type': '',
      'discount_value': 0,
      'customer': {},
    });
  }

  /*CREATE IN FIREBASE*/

  async getTicketAndSendEmail(uid_ticket, type) {
    var ticket = await this.getTicket(uid_ticket);
    this.sendEticketEmail(ticket, type);
    this.sendEmailRestaurant(ticket);
  }

  async getTicket(uid) {
    const ticketRef = this.firestore.collection('tickets').doc(uid);

    var ticket;

    await ticketRef.get().then(doc => {
      ticket = doc.data();
      ticket.UID = doc.id;
    });

    return ticket
  }

  async sendEticketEmail(ticket, type) {
    //Afegim en el ticket el preu de envio
    var envio = await this.insertSendingPriceTicket();
    ticket['list'].push(envio);
    //get currency symbol
    var str = this.globals.restaurant.currency;
    var user_currency = str.substring(str.lastIndexOf("(") + 1,
      str.lastIndexOf(")"));
    var currency_symbol = getCurrencySymbol(user_currency, 'narrow', 'es');

    /*const params = new HttpParams()
      .set('customer', JSON.stringify(this.customer))
      .set('restaurant', JSON.stringify(this.globals.restaurant))
      .set('ticket', JSON.stringify(ticket))
      .set('subtotal', this.subtotal.toString())
      .set('tax_name', this.globals.restaurant_tax_name)
      .set('tax', this.globals.restaurant_tax.toString())
      .set('currency_symbol', currency_symbol)
      .set('ticket_date', this.globals.timestampToDate(ticket.date))
      .set('type', type);
    this.http.post(this.globals.api_path + 'eticket/customer_email', params, {})
      .subscribe(res => {
        if (res['task']) {// email send ok
          this.insertAlert('Ticket electrónico enviado correctamente.', 'success');
        } else { // email send error
          this.insertAlert('Hubo un error al enviarse el ticket electrónico.', 'danger');
        }
      });*/

    /**add to cloud functions emails**/
    await this.afs.collection('_emails').add({
      UID_RESTAURANT: this.globals.restaurant_uid,
      type: 'eticket_customer',
      customer_name: this.customer.name,
      customer_email: this.customer.email,
      customer_phone: this.customer.phone,
      takeaway_type: 'takeaway',
      ticket_number: ticket.number,
      ticket_list: ticket['list'],
      ticket_date: this.globals.timestampToDate(ticket.date),
      ticket_currency: currency_symbol,
      ticket_subtotal: this.subtotal,
      ticket_total: ticket.amount,
      ticket_tax_name: this.globals.restaurant_tax_name,
      ticket_tax: this.globals.restaurant_tax,
      restaurant_company: this.globals.restaurant.company,
      restaurant_address: this.globals.restaurant.address,
      restaurant_email: this.globals.restaurant.email,
      restaurant_phone: this.globals.restaurant.phone,
      restaurant_logo: 'https://firebasestorage.googleapis.com/v0/b/mycheftool-d3899.appspot.com/o/' + this.replaceUri(this.globals.restaurant.logo) + '?alt=media',
    }).then(() => {
      this.insertAlert('Ticket electrónico enviado correctamente.', 'success');
    }).catch(() => {
      this.insertAlert('Hubo un error al enviarse el ticket electrónico.', 'danger');
    });
  }

  sendEmailRestaurant(ticket) {
    const params = new HttpParams()
      .set('ticket_num', ticket.number)
      .set('ticket_date', this.globals.timestampToDate(ticket.date))
      .set('restaurant_email', this.globals.restaurant.email);
    this.http.post(this.globals.api_path + 'eticket/restaurant_email', params, {}).subscribe(res => {
      //console.log(res);
    });

  }

  async insertAlert(msg, style) {
    await this.afs.collection('stripe_customers/' + this.globals.doc_stripe + '/alerts').add({
      'alert_msg': msg,
      'alert_style': style,
    })
  }

  isZipCodeValid(zip_code) {
    if (this.globals.zip_codes.findIndex(x => x.code == zip_code) >= 0) {
      return true
    } else {
      return false;
    }
  }

  async addPaymentIntentInCharges(paymentIntent) {
    //crear charge en fb
    var response_charge = await this.afs.collection('restaurants/' + this.globals.restaurant_uid + '/charges').add({
      type: 'paymentIntent',
      date: new Date(),
    });


    //hacer merge de la nueva informacion al charge
    const response_update = await this.afs.collection('restaurants/' + this.globals.restaurant_uid + '/charges').doc(response_charge.id).set(paymentIntent, {
      merge: true
    });

    return response_charge.id;

  }

  savePaymentIntentInCharges(pi_id, uid_ticket, num_ticket) {
    const params = new HttpParams()
      .set('public_key', this.globals.public_key)
      .set('pi_id', pi_id)
      .set('ticket_number', num_ticket)
      .set('uid_ticket', uid_ticket);
    this.http.post(this.globals.api_data_path + 'stripe/get_charges_by_payment_intent_and_save', params, {}).subscribe(res => {
      //console.log(res);
    });
  }

  replaceUri(url) {
    return url.replace('logo/', 'logo%2F')
  }

  notifMinStock(stock, min_stock, element_name) {
    //Si el min_stock es diferente a 0, y si el elemento es igual o menor al minimo de stock, se envia una notificacion
    if (min_stock != 0 && stock <= min_stock) {
      let _notification = {};

      //Preparamos la notificacion en enviar
      _notification = {
        'UID_RESTAURANT': this.globals.restaurant_uid,
        'title': this.globals.trans_lang[this.globals.lang]['notification_push_call_min_stock_title'],
        'description': this.globals.trans_lang[this.globals.lang]['notification_push_call_min_stock_desc_1'] +" "+ element_name +" "+this.globals.trans_lang[this.globals.lang]['notification_push_call_min_stock_desc_2'] ,
      }
      //Enviamos la notificacion
      this.notificationsServices.notificationPushAllRestaurant(_notification);
    }
  }

}

