import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { firebaseConfig } from '../environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { Stripe } from '@ionic-native/stripe/ngx';
import { ComponentsModule } from './components/components.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { CurrencyPipe } from '@angular/common';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt), 'it';
registerLocaleData(localeDe, 'de');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeEn), 'en';
registerLocaleData(localeEs, 'es');

import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ComponentsModule,
    HttpClientModule,
  ],

  providers: [
    StatusBar,
    SplashScreen,
    Stripe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
