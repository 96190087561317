import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  private firestore: any;
  public order_tmp: any = [];
  public order_cesta: any = [];
  public restaurant_uid: string;
  public restaurant_tax: number = 10;
  public restaurant_tax_name: string = 'IVA';
  public user_currency: any;
  public restaurant: any = [];
  public restaurant_logo: any;
  public restaurant_cover: any;
  public web_aboutus_img1: any;
  public web_aboutus_img2: any;
  public web_aboutus_img3: any;
  public zip_codes: any = [];
  public lang: any;
  public public_key: any;
  public domini: any;
  public alerts: any;
  public doc_stripe: any;
  public payments_show: boolean = true;
  public all_menus: any = [];
  public api_path: string = 'https://api.mycheftool.com/api/';
  //public api_path: any = 'http://localhost/apiv2.mycheftool.com/public/api/';
  public api_data_path: string = 'https://data-mycheftool.appspot.com/';
  //public api_data_path: string = 'http://localhost:8000/';
  public version = '1.17';
  public users_array;

  constructor(public http: HttpClient,) {
    this.firestore = firebase.firestore();
  }

  public objectToArray(object) {
    var _return = Object.keys(object).map(function (index) {
      let _array = object[index];
      return _array;
    });
    return _return;
  }

  nameLangSelectMenuDish(lang, dish) {
    switch (lang) {
      case 'es': {
        //statements; 
        return dish.name
      }
      case 'ca': {
        //statements;
        return dish.name_ca
      }
      case 'en': {
        //statements;
        return dish.name_en
      }
      case 'de': {
        //statements;
        return dish.name_de
      }
      case 'fr': {
        //statements;
        return dish.name_fr
      }
      case 'it': {
        //statements;
        return dish.name_it
      }
      default: {
        //statements; 
        return dish.name
      }
    }
  }

  nameProductLangSelect(lang, product) {
    switch (lang) {
      case 'es': {
        //statements; 
        return product.name
      }
      case 'ca': {
        //statements;
        return product.name_ca
      }
      case 'en': {
        //statements;
        return product.name_en
      }
      case 'de': {
        //statements;
        return product.name_de
      }
      case 'fr': {
        //statements;
        return product.name_fr
      }
      case 'it': {
        //statements;
        return product.name_it
      }
      default: {
        //statements; 
        return product.name
      }
    }
  }

  nameLangSelect(lang, element) {
    switch (lang) {
      case 'es': {
        //statements; 
        return element.name_es

      }
      case 'ca': {
        //statements;
        return element.name_ca

      }
      case 'en': {
        //statements;
        return element.name_en

      }
      case 'de': {
        //statements;
        return element.name_de

      }
      case 'fr': {
        //statements;
        return element.name_fr

      }
      case 'it': {
        //statements;
        return element.name_it

      }
      default: {
        //statements;
        return element.name_es

      }
    }
  }

  /***/
  async getCashFirst() {
    const variationsRef = this.firestore.collection('cashs');

    var cashs = [];

    await variationsRef.where('UID_RESTAURANT', '==', this.restaurant_uid)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {

          var cash = doc.data();
          cash.UID = doc.id;

          cashs.push(cash);
        });
      });

    return cashs
  }

  checkUndefined(element) {
    if (element) {
      return element;
    } else {
      return '';
    }
  }

  async delay(ms) {
    // return await for better async stack trace support in case of errors.
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  timestampToDate(date) {
    if (date.seconds) {
      var _date = new Date(date.seconds * 1000);
    } else {
      var _date = new Date(date._seconds * 1000);
    }

    return moment(_date).format('DD/MM/YYYY HH:mm');
  }

  checkAddonOpened() {
    /*if (this.restaurant.addon_all) {
      return true;
    } else {
      return false;
    }*/
    if (this.restaurant.license == 'business') {
      return true;
    } else {
      return false;
    }
  }
  checkAboutUs() {
    //Si hay titulo de About us i texto de about us
    if (this.restaurant.web_text_title_aboutus || this.restaurant.web_text_large_aboutus) {
      return true;
    } else {
      return false;
    }
  }

  getElementImg(value) {
    let _img_url = 'https://firebasestorage.googleapis.com/v0/b/mycheftool-d3899.appspot.com/o/' + encodeURIComponent(value) + '?alt=media';
    return _img_url;
  }

  returnNameByLang(element) {
    switch (this.lang) {
      case 'es': {
        //statements; 
        return element.name_es
      }
      case 'ca': {
        //statements;
        return element.name_ca

      }
      case 'en': {
        //statements;
        return element.name_en

      }
      case 'de': {
        //statements;
        return element.name_de

      }
      case 'fr': {
        //statements;
        return element.name_fr

      }
      case 'it': {
        //statements;
        return element.name_it

      }
      default: {
        //statements; 
        return element.name

      }
    }
  }

  async getUsersFromNode() {
    this.users_array = [];
    //get users from node
    const params = new HttpParams()
      .set('public_key', this.public_key);
    await this.http.post(this.api_data_path + 'users/get_users_v2', params, {})
      .subscribe(async res => {
        this.users_array = res['users'];
      });
  }


  public trans_lang = {
    es: {
      name: "Nombre",
      phone: "Teléfono",
      email: "E-mail",
      date: "Fecha",
      time: "Hora",
      adults: "Adultos",
      children: "Niños",
      comments: "Comentarios",
      privacy_policy: "Acepto la politica de privacidad",
      send_reservation: "Enviar",
      select_another_date: "No hay disponibilidad para esta fecha. Selecciona otra fecha.",
      reservation_sent_correctly: "RESERVA ENVIADA CORRECTAMENTE",
      accordance_email: "Recibirá la conformidad en su correo electrónico.",
      not_able_check_reservation: "No hemos podido comprobar la disponibilidad de la reserva.",
      complete_all_camps: "Completa todos los campos del formulario con *",
      contact: "Contacto",
      reservation: "Reservas",
      menu: "Cartas",
      daily_menus: "Menús",
      allergens: "Alérgenos",
      included: "incluido",
      pva_incluido: "Pan, vino y agua incluido",
      pva_no_incluido: "Pan, vino y agua NO incluido",
      cafe_incluido: "Café incluido",
      cafe_no_incluido: "Café NO incluido",
      refrescos_incluidos: "Refrescos incluidos",
      refrescos_no_incluidos: "Refrescos NO incluidos",
      racion: "Ración",
      tapa: "Tapa",
      zonas_reparto: 'Zonas de reparto',
      tu_pedido: 'TU PEDIDO',
      total: 'TOTAL',
      confirmar_pagar: "CONFIRMAR Y PAGAR",
      listo_para_pedir: "LISTO PARA PEDIR",
      anadir: "AÑADIR",
      cerrar: "Cerrar",
      alcohol: "Alcohol",
      huevo: "Huevo *",
      altramueces: "Altramueces",
      crustaceos: "Crustáceos *",
      frutos_secos: "Frutos secos *",
      gluten: "Gluten *",
      setas: "Setas",
      apio: "Apio",
      pescado: "Pescado *",
      lactosa: "Lactosa *",
      soja: "Soja *",
      picante_suave: "Picante Suave",
      picante: "Picante",
      muy_picante: "Muy picante",
      maiz: "Maíz",
      cacahuetes: "Cacahuetes",
      vegetariano: "Vegetariano",
      vegano: "Vegano",
      dioxido_azufre_sulfitos: "Dióxido de azufre y sulfitos *",
      ecologico: "Ecológico",
      sesamo: "Sesamo",
      kosher: "Kosher",
      mostaza: "Mostaza",
      cerdo: "Cerdo",
      moluscos: "Moluscos",
      cafeina: "Cafeína",
      halal: "Halal",
      infantil: "Infantil",
      km0: "KM0",
      mediterraneo: "Mediterráneo",
      comida_rapida: "Comida rápida",
      recomendado: "Recomendado",
      sin_azucar: "Sin azúcar",
      estevia: "Estevia",
      poner_cantidad_correcta: "Poner cantidad correcta",
      la_seccion: "La sección",
      es_obligatoria: "es obligatoria.",
      cantidad: "Cantidad",
      cancelar: "Cancelar",
      guardar: "Añadir",
      codigos_postales: "Códigos postales",
      subtotal: "SUBTOTAL",
      elements: "ELEMENTOS",
      edit: "Editar",
      payment: "Pago",
      cvv: "CVV",
      sending: "Envio",
      campos_obligatorios: "* campos obligatorios",
      mesa: "Mesa",
      recoger: "Recoger",
      a_domicilio: "A domicilio",
      datos_contacto: "Datos de contacto",
      direccion: "Dirección",
      n_piso_puerta: "Nº/Piso/Puerta",
      c_p: "C.P.",
      localidad: "Localidad",
      pais: "País",
      fecha_entrega: "Fecha entrega",
      hora_entrega: "Hora entrega",
      comentario: "Comentario",
      acepto_privacidad_condiciones: "Acepto la política de privacidad y condiciones de uso",
      volver_al_pedido: "volver al pedido",
      siguiente: "Siguiente",
      fecha_recogida: "Fecha recogida",
      hora_recogida: "Hora recogida",
      correo_electronico: "Correo electrónico",
      email_para_ticket_compra: "El correo electrónico es necesario para recibir el ticket de compra",
      datos: "Datos",
      metodo: "Método",
      n_tarjeta: "Nº tarjeta",
      fecha_caducidad: "Fecha de caducidad",
      realizar_pedido: "REALIZAR PEDIDO",
      pedido_realizado_correcto: "¡PEDIDO REALIZADO CORRECTAMENTE!",
      llegara_ticket_a_email: "Recuerda que en tu correo electrónico te llegará el ticket de compra",
      volver_tienda: "Volver a la tienda",
      fallo_pago: "¡FALLO EN EL PAGO DEL PEDIDO!",
      fallo_revisa: "Algo ha fallado, por favor revisa todos los campos y vuélvelo a intentar",
      faltan_datos: "Completa todos los datos para continuar.",
      cp_no_valido: "Lo sentimos en este código postal no repartimos.",
      comprando: "Completando proceso de pago. Por favor no cierre esta ventana.",
      error_pago: "No hemos podido completa el pago. Vuelva a intentarlo.",
      error_source: "Tarjeta no valida. Vuelva a intentarlo.",
      error_customer: "No podemos completar el pedido. Revisa el email, teléfono y los otros datos, vuelve a intentarlo.",
      card_info: "Completa la información de la tarjeta.",
      horarios: "Horarios",
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
      open: 'Abierto',
      closed: 'Cerrado',
      privacy_policy_footer: 'Política de privacidad',
      terms_conditions: 'Términos y condiciones',
      menu_allergens: "Este es un menú que contiene alérgenos, por favor póngase en contacto con el camarero para más información",
      lang_espanol: "Español",
      lang_catala: "Catalán",
      lang_ingles: "Inglés",
      lang_aleman: "Alemán",
      lang_frances: "Francés",
      lang_italiano: "Italiano",
      select_lang: "Selecciona idioma",
      cargando_horario: "Cargando horario",
      notification_push_new_order_title: "Nuevo pedido",
      notification_push_new_order_desc_1: "Tienes un nuevo pedido de TakeAway/Delivery de Sites",
      notification_push_call_min_stock_title: "!Atención: Límite de stock¡",
      notification_push_call_min_stock_desc_1: "Has llegado al límite de stock del elemento ",
      notification_push_call_min_stock_desc_2: " revisalo para no quedarte sin stock",
      see_schedule: "Horario"
    },
    ca: {
      name: "Nom",
      phone: "Telèfon",
      email: "E-mail",
      date: "Data",
      time: "Hora",
      adults: "Adults",
      children: "Nens",
      comments: "Comentaris",
      privacy_policy: "Accepto la política de privacitat",
      send_reservation: "Enviar",
      select_another_date: "No hi ha disponibilitat per a aquesta data. Selecciona una altra data.",
      reservation_sent_correctly: "RESERVA ENVIADA CORRECTAMENT",
      accordance_email: "Rebrà la conformitat en el seu correu electrònic.",
      not_able_check_reservation: "No hem pogut comprovar la disponibilitat de la reserva.",
      complete_all_camps: "Completa tots els camps del formulari amb *",
      contact: "Contacte",
      reservation: "Reserves",
      menu: "Cartes",
      daily_menus: "Menús",
      allergens: "Al·lèrgens",
      included: "inclòs",
      pva_incluido: "Pa, vi i aigua inclosos",
      pva_no_incluido: "Pa, vi i aigua NO inclosos",
      cafe_incluido: "Cafè inclòs",
      cafe_no_incluido: "Cafè NO inclòs",
      refrescos_incluidos: "Refrescos inclosos",
      refrescos_no_incluidos: "Refrescos NO inclosos",
      racion: "Ració",
      tapa: "Tapa",
      zonas_reparto: 'Zones de repartiment',
      tu_pedido: 'LA SEVA COMANDA',
      total: 'TOTAL',
      confirmar_pagar: "CONFIRMAR I PAGAR",
      listo_para_pedir: "A PUNT PER DEMANAR",
      anadir: "AFEGIR",
      cerrar: "Tancar",
      alcohol: "AlcoholAlcohol",
      huevo: "Ou *",
      altramueces: "Tramús",
      crustaceos: "Crustacis *",
      frutos_secos: "Fruits secs *",
      gluten: "Gluten *",
      setas: "Bolets",
      apio: "Api",
      pescado: "Peix *",
      lactosa: "Lactosa *",
      soja: "Soja *",
      picante_suave: "Picant suau",
      picante: "Picant",
      muy_picante: "Molt picant",
      maiz: "Blat de moro",
      cacahuetes: "Cacauets",
      vegetariano: "Vegetarià",
      vegano: "Vegà",
      dioxido_azufre_sulfitos: "Diòxid de sofre i sulfits *",
      ecologico: "Ecològic",
      sesamo: "Sèsam",
      kosher: "Kosher",
      mostaza: "Mostassa",
      cerdo: "Porc",
      moluscos: "Mol·luscs",
      cafeina: "Cafeïna",
      halal: "Halal",
      infantil: "Infantil",
      km0: "KM0",
      mediterraneo: "Mediterrani",
      comida_rapida: "Menjar ràpid",
      recomendado: "Recomanat",
      sin_azucar: "Sense sucre",
      estevia: "Estèvia",
      poner_cantidad_correcta: "Posar quantitat correcta",
      la_seccion: "La secció",
      es_obligatoria: "és obligatòria.",
      cantidad: "Quantitat",
      cancelar: "Cancelar",
      guardar: "Afegir",
      codigos_postales: "Codis postals",
      subtotal: "SUBTOTAL",
      elements: "ELEMENTS",
      edit: "Edita",
      payment: "Pagament",
      cvv: "CVV",
      sending: "Enviament",
      campos_obligatorios: "* camps obligatoris",
      mesa: "Taula",
      recoger: "Recollir",
      a_domicilio: "A domicili",
      datos_contacto: "Dades de contacte",
      direccion: "Direcció",
      n_piso_puerta: "Nº/Pis/Porta",
      c_p: "C.P.",
      localidad: "Localitat",
      pais: "País",
      fecha_entrega: "Data d'entrega",
      hora_entrega: "Hora entrega",
      comentario: "Comentari",
      acepto_privacidad_condiciones: "Accepto la política de privacitat i condicions d'ús",
      volver_al_pedido: "tornar a la comanda",
      siguiente: "Següent",
      fecha_recogida: "Data recollida",
      hora_recogida: "Hora recollida",
      correo_electronico: "Correu electrònic",
      email_para_ticket_compra: "El correu electrònic és necessari per rebre el tiquet de compra",
      datos: "Dades",
      metodo: "Mètode",
      n_tarjeta: "Nº targeta",
      fecha_caducidad: "Data de caducitat",
      realizar_pedido: "REALITZAR COMANDA",
      pedido_realizado_correcto: "!COMANDA REALITZADA CORRECTAMENT!",
      llegara_ticket_a_email: "Recorda que en el teu correu electrònic t'arribarà el tiquet de compra",
      volver_tienda: "Tornar a la botiga",
      fallo_pago: "¡ERROR EN EL PAGAMENT DE LA COMANDA!",
      fallo_revisa: "Alguna cosa ha fallat, si us plau revisa tots els camps i torna-ho a intentar",
      faltan_datos: "Completa totes les dades per a continuar.",
      cp_no_valido: "Malauradament en aquest codi postal no repartim.",
      comprando: "Completant procés de pagament. Si us plau no tanqui aquesta finestra.",
      error_pago: "No hem pogut completar el pagament. Torneu a intentar-ho.",
      error_source: "Targeta no valida. Torneu a intentar-ho.",
      error_customer: "No podem completar la comanda. Revisa l'email, telèfon i les altres dades, torna a intentar-ho.",
      card_info: "Completa la informació de la targeta.",
      horarios: "Horaris",
      monday: 'Dilluns',
      tuesday: 'Dimarts',
      wednesday: 'Dimecres',
      thursday: 'Dijous',
      friday: 'Divendres',
      saturday: 'Dissabte',
      sunday: 'Diumenge',
      open: 'Obert',
      closed: 'Tancat',
      privacy_policy_footer: 'Política de privacitat',
      terms_conditions: 'Termes i condicions',
      menu_allergens: "Aquest és un menú que conté al·lèrgens, si us plau poseu-vos en contacte amb el cambrer per a més informació",
      lang_espanol: "Espanyol",
      lang_catala: "Català",
      lang_ingles: "Anglès",
      lang_aleman: "Alemany",
      lang_frances: "Francès",
      lang_italiano: "Italià",
      select_lang: "Seleccioneu l'idioma",
      cargando_horario: "Carregant horari",
      notification_push_new_order_title: "Nova comanda",
      notification_push_new_order_desc_1: "Tens una nova comanda de TakeAway/Delivery de Sites",
      notification_push_call_min_stock_title: "Atenció: límit d’estoc!",
      notification_push_call_min_stock_desc_1: "Has arribat al límit d'estoc de l'element ",
      notification_push_call_min_stock_desc_2: " revisa’l per a no quedar-te sense estoc",
      see_schedule: "Horari"
    },
    en: {
      name: "Name",
      phone: "Phone",
      email: "E-mail",
      date: "Date",
      time: "Time",
      adults: "Adults",
      children: "Children",
      comments: "Comments",
      privacy_policy: "I accept the Privacy Policy",
      send_reservation: "Send",
      select_another_date: "No availability for this date. Select another date.",
      reservation_sent_correctly: "SENT CORRECTLY",
      accordance_email: "You will receive the confirmation in your email.",
      not_able_check_reservation: "We have not been able to check the availability of the reservation.",
      complete_all_camps: "Required fields *",
      contact: "Contact",
      reservation: "Reservations",
      menu: "Menus",
      daily_menus: "Daily menus",
      allergens: "Allergens",
      included: "included",
      pva_incluido: "Bread, wine and water included",
      pva_no_incluido: "Bread, wine and water NOT included",
      cafe_incluido: "Coffee included",
      cafe_no_incluido: "Coffee NOT included",
      refrescos_incluidos: "Soft drinks included",
      refrescos_no_incluidos: "Soft drinks NOT included",
      racion: "Portion",
      tapa: "Bar snack",
      zonas_reparto: 'Delivery areas',
      tu_pedido: 'YOUR ORDER',
      total: 'TOTAL',
      confirmar_pagar: "CONFIRM AND PAY",
      listo_para_pedir: "READY TO ORDER",
      anadir: "ADD",
      cerrar: "Close",
      alcohol: "Alcohol",
      huevo: "Egg *",
      altramueces: "Lupin bean",
      crustaceos: "Crustaceans *",
      frutos_secos: "Nuts *",
      gluten: "Gluten *",
      setas: "Mushrooms",
      apio: "Celery",
      pescado: "Fish *",
      lactosa: "Lactose *",
      soja: "Soy *",
      picante_suave: "Soft spicy",
      picante: "Spicy",
      muy_picante: "Very spicy",
      maiz: "Corn",
      cacahuetes: "Peanuts",
      vegetariano: "Vegetarian",
      vegano: "Vegan",
      dioxido_azufre_sulfitos: "Sulfur dioxide and sulphites *",
      ecologico: "Ecological",
      sesamo: "Sesame",
      kosher: "Kosher",
      mostaza: "Mustard",
      cerdo: "Pork",
      moluscos: "Mollusks",
      cafeina: "Caffeine",
      halal: "Halal",
      infantil: "Children",
      km0: "KM0",
      mediterraneo: "Mediterranean",
      comida_rapida: "Fast food",
      recomendado: "Recommended",
      sin_azucar: "Sugar-free",
      estevia: "Stevia",
      poner_cantidad_correcta: "Put correct amount",
      la_seccion: "The section",
      es_obligatoria: "it is required.",
      cantidad: "Quantity",
      cancelar: "Cancel",
      guardar: "Add",
      codigos_postales: "Zip codes",
      subtotal: "SUBTOTAL",
      elements: "ELEMENTS",
      edit: "Edit",
      payment: "Payment",
      cvv: "CVV",
      sending: "Sending",
      campos_obligatorios: "* required fields",
      mesa: "Table",
      recoger: "Pick up",
      a_domicilio: "Delivery",
      datos_contacto: "Contact information",
      direccion: "Address",
      n_piso_puerta: "Nº/Floor/Door",
      c_p: "Z.P.",
      localidad: "Location",
      pais: "Country",
      fecha_entrega: "Delivery date",
      hora_entrega: "Delivery time",
      comentario: "Comment",
      acepto_privacidad_condiciones: "I accept the privacy policy and conditions of use",
      volver_al_pedido: "back to order",
      siguiente: "Next",
      fecha_recogida: "Pickup date",
      hora_recogida: "Pickup time",
      correo_electronico: "Email address",
      email_para_ticket_compra: "E-mail is required to receive the ticket purchase",
      datos: "Data",
      metodo: "Method",
      n_tarjeta: "Card number",
      fecha_caducidad: "Expiration date",
      realizar_pedido: "MAKE AN ORDER",
      pedido_realizado_correcto: "!ORDER PERFORMED CORRECTLY!",
      llegara_ticket_a_email: "Remember that you will receive the purchase ticket in your email",
      volver_tienda: "Return to the store",
      fallo_pago: "¡FAILURE TO PAY THE ORDER!",
      fallo_revisa: "Something went wrong, please check all the fields and try again",
      faltan_datos: "Complete all data to continue.",
      cp_no_valido: "Sorry in this zip code we do not deliver.",
      comprando: "Completing the payment process. Please do not close this window.",
      error_pago: "We were unable to complete the payment. Try again.",
      error_source: "Invalid card. Try again.",
      error_customer: "We are unable to complete the order. Check the email, phone and other information, try again.",
      card_info: "Complete card information.",
      horarios: "Schedule",
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      open: 'Open',
      closed: 'Closed',
      privacy_policy_footer: 'Privacy policy',
      terms_conditions: 'Terms and Conditions',
      menu_allergens: "This is a menu that contains allergens, please contact the waiter for more information",
      lang_espanol: "Spanish",
      lang_catala: "Catalan",
      lang_ingles: "English",
      lang_aleman: "German",
      lang_frances: "French",
      lang_italiano: "Italian",
      select_lang: "Select language",
      cargando_horario: "Loading schedule",
      notification_push_new_order_title: "New order",
      notification_push_new_order_desc_1: "You have a new TakeAway / On-Site Delivery order",
      notification_push_call_min_stock_title: "Warning: stock limit!",
      notification_push_call_min_stock_desc_1: "You have reached the item's stock limit ",
      notification_push_call_min_stock_desc_2: " go through it so that you don't run out of stock",
      see_schedule: "Schedule"
    },
    it: {
      name: "Nome",
      phone: "Telefono",
      email: "E-mail",
      date: "Data",
      time: "Ora",
      adults: "Adulti",
      children: "Bambini",
      comments: "Commenti",
      privacy_policy: "Accetto la Privacy Policy",
      send_reservation: "Inviare",
      select_another_date: "Non c'è disponibilità per questa data. Seleziona un'altra data.",
      reservation_sent_correctly: "PRENOTAZIONE INVIATA CORRETTAMENTE",
      accordance_email: "Riceverai la risposta nella tua email.",
      not_able_check_reservation: "Non abbiamo potuto verificare la disponibilità della prenotazione.",
      complete_all_camps: "Completa tutti i campi del modulo con *",
      contact: "Contatto",
      reservation: "Prenotazioni",
      menu: "Menu",
      daily_menus: "Menu",
      allergens: "Allergeni",
      included: "incluso",
      pva_incluido: "Pane, vino e acqua inclusi",
      pva_no_incluido: "Pane, vino e acqua NON inclusi",
      cafe_incluido: "Caffè incluso",
      cafe_no_incluido: "Caffè NON incluso",
      refrescos_incluidos: "Bevande inclusi",
      refrescos_no_incluidos: "Bevande NON incluse",
      racion: "Porzione",
      tapa: "Tapa",
      zonas_reparto: "Zone di consegna",
      tu_pedido: "IL TUO ORDINE",
      total: "TOTALE",
      confirmar_pagar: "CONFERMA E PAGA",
      listo_para_pedir: "PRONTO PER ORDINARE",
      anadir: "INSERISCI",
      cerrar: "Chiudere",
      alcohol: "Alcol",
      huevo: "Uovo *",
      altramueces: "Lupini",
      crustaceos: "Crostacei *",
      frutos_secos: "Noccioline *",
      gluten: "Glutine *",
      setas: "Funghi",
      apio: "Sedano",
      pescado: "Pesce *",
      lactosa: "Lattosio *",
      soja: "Soia *",
      picante_suave: "Leggero piccante",
      picante: "Piccante",
      muy_picante: "Molto piccante",
      maiz: "Mais",
      cacahuetes: "Arachidi",
      vegetariano: "Vegetariano",
      vegano: "Vegano",
      dioxido_azufre_sulfitos: "Anidride solforosa e solfiti *",
      ecologico: "Ecologico",
      sesamo: "Sesamo",
      kosher: "Kosher",
      mostaza: "Mostarda",
      cerdo: "Maiale",
      moluscos: "Molluschi",
      cafeina: "Caffeina",
      halal: "Halal",
      infantil: "Bambino",
      km0: "KM0",
      mediterraneo: "mediterraneo",
      comida_rapida: "Fast food",
      recomendado: "Consigliato",
      sin_azucar: "Senza zucchero",
      estevia: "Stevia",
      poner_cantidad_correcta: "Metti l'importo corretto",
      la_seccion: "La sezione",
      es_obligatoria: "è obbligatorio.",
      cantidad: "Quantità",
      cancelar: "Annulla",
      guardar: "Salva",
      codigos_postales: "Codice postale",
      subtotal: "TOTALE ",
      elements: "ELEMENTI",
      edit: "modificare",
      payment: "Pagamento",
      cvv: "CVV",
      sending: "spedizione",
      campos_obligatorios: "* Campi richiesti",
      mesa: "tavolo",
      recoger: "Asporto",
      a_domicilio: "A casa",
      datos_contacto: "Informazioni sui contatti",
      direccion: "Indirizzo",
      n_piso_puerta: "No. / Piano / Porta",
      c_p: "C.P.",
      localidad: "Posizione",
      pais: "nazione",
      fecha_entrega: "data della consegna",
      hora_entrega: "Tempo di consegna",
      comentario: "Commento",
      acepto_privacidad_condiciones: "Accetto l'informativa sulla privacy e le condizioni d'uso",
      volver_al_pedido: "torna all'ordine",
      siguiente: "Il prossimo",
      fecha_recogida: "Data di ritiro",
      hora_recogida: "Tempo di raccolta",
      correo_electronico: "E-mail",
      email_para_ticket_compra: "L'email è necessaria per ricevere la ricevuta di acquisto",
      datos: "Dati",
      metodo: "Metodo",
      n_tarjeta: "Numero di carta",
      fecha_caducidad: "data di scadenza",
      realizar_pedido: "EFFETTUARE UN ORDINE",
      pedido_realizado_correcto: "ORDINE EFFETTUATO CORRETTAMENTE!",
      llegara_ticket_a_email: "Ricorda che riceverai la ricevuta di acquisto nella tua email",
      volver_tienda: "Ritorna allo negozio",
      fallo_pago: "MANCATO PAGAMENTO DELL'ORDINE!",
      fallo_revisa: "Qualcosa è andato storto, controlla tutti i campi e riprova",
      faltan_datos: "Compila tutti i dettagli per continuare.",
      cp_no_valido: "Siamo spiacenti in questo codice postale che non consegniamo.",
      comprando: "Completamento del processo di pagamento. Per favore non chiudere questa finestra.",
      error_pago: "Non siamo stati in grado di completare il pagamento. Riprova.",
      error_source: "Carta non valida. Riprova.",
      error_customer: "Non siamo in grado di completare l'ordine. Controlla l'e-mail, il telefono e altre informazioni, riprova.",
      card_info: "Compila le informazioni sulla scheda.",
      horarios: "Orari",
      monday: "Lunedi",
      tuesday: "martedì",
      wednesday: "mercoledì",
      thursday: "giovedi",
      friday: "Venerdì",
      saturday: "Sabato",
      sunday: "Domenica",
      open: "Aperto",
      closed: "Chiuso",
      privacy_policy_footer: "Politica sulla riservatezza",
      terms_conditions: "Termini e condizioni",
      menu_allergens: "Questo è un menu che contiene allergeni, contatta il cameriere per maggiori informazioni",
      lang_espanol: "Spagnolo",
      lang_catala: "Catalano",
      lang_ingles: "Inglese",
      lang_aleman: "Tedesco",
      lang_frances: "Francese",
      lang_italiano: "Italiano",
      select_lang: "Seleziona la lingua",
      cargando_horario: "",
      notification_push_new_order_title: "Nuovo ordine",
      notification_push_new_order_desc_1: "Hai un nuovo ordine di TakeAway/Consegna del sito",
      notification_push_call_min_stock_title: "Attenzione: limite di scorta!",
      notification_push_call_min_stock_desc_1: "Hai raggiunto il limite di stock dell'articolo ",
      notification_push_call_min_stock_desc_2: " controllalo in modo da non esaurire le scorte",
      see_schedule: "Programma"
    },
    fr: {
      name: "Name",
      phone: "Phone",
      email: "E-mail",
      date: "Date",
      time: "Time",
      adults: "Adults",
      children: "Children",
      comments: "Comments",
      privacy_policy: "I accept the Privacy Policy",
      send_reservation: "Send",
      select_another_date: "No availability for this date. Select another date.",
      reservation_sent_correctly: "SENT CORRECTLY",
      accordance_email: "You will receive the confirmation in your email.",
      not_able_check_reservation: "We have not been able to check the availability of the reservation.",
      complete_all_camps: "Required fields *",
      contact: "Contact",
      reservation: "Reservations",
      menu: "Menus",
      daily_menus: "Daily menus",
      allergens: "Allergens",
      included: "included",
      pva_incluido: "Bread, wine and water included",
      pva_no_incluido: "Bread, wine and water NOT included",
      cafe_incluido: "Coffee included",
      cafe_no_incluido: "Coffee NOT included",
      refrescos_incluidos: "Soft drinks included",
      refrescos_no_incluidos: "Soft drinks NOT included",
      racion: "Portion",
      tapa: "Bar snack",
      zonas_reparto: 'Delivery areas',
      tu_pedido: 'YOUR ORDER',
      total: 'TOTAL',
      confirmar_pagar: "CONFIRM AND PAY",
      listo_para_pedir: "READY TO ORDER",
      anadir: "ADD",
      cerrar: "Close",
      alcohol: "Alcohol",
      huevo: "Egg *",
      altramueces: "Lupin bean",
      crustaceos: "Crustaceans *",
      frutos_secos: "Nuts *",
      gluten: "Gluten *",
      setas: "Mushrooms",
      apio: "Celery",
      pescado: "Fish *",
      lactosa: "Lactose *",
      soja: "Soy *",
      picante_suave: "Soft spicy",
      picante: "Spicy",
      muy_picante: "Very spicy",
      maiz: "Corn",
      cacahuetes: "Peanuts",
      vegetariano: "Vegetarian",
      vegano: "Vegan",
      dioxido_azufre_sulfitos: "Sulfur dioxide and sulphites *",
      ecologico: "Ecological",
      sesamo: "Sesame",
      kosher: "Kosher",
      mostaza: "Mustard",
      cerdo: "Pork",
      moluscos: "Mollusks",
      cafeina: "Caffeine",
      halal: "Halal",
      infantil: "Children",
      km0: "KM0",
      mediterraneo: "Mediterranean",
      comida_rapida: "Fast food",
      recomendado: "Recommended",
      sin_azucar: "Sugar-free",
      estevia: "Stevia",
      poner_cantidad_correcta: "Put correct amount",
      la_seccion: "The section",
      es_obligatoria: "it is required.",
      cantidad: "Quantity",
      cancelar: "Cancel",
      guardar: "Add",
      codigos_postales: "Zip codes",
      subtotal: "SUBTOTAL",
      elements: "ELEMENTS",
      edit: "Edit",
      payment: "Payment",
      cvv: "CVV",
      sending: "Sending",
      campos_obligatorios: "* required fields",
      mesa: "Table",
      recoger: "Pick up",
      a_domicilio: "Delivery",
      datos_contacto: "Contact information",
      direccion: "Address",
      n_piso_puerta: "Nº/Floor/Door",
      c_p: "Z.P.",
      localidad: "Location",
      pais: "Country",
      fecha_entrega: "Delivery date",
      hora_entrega: "Delivery time",
      comentario: "Comment",
      acepto_privacidad_condiciones: "I accept the privacy policy and conditions of use",
      volver_al_pedido: "back to order",
      siguiente: "Next",
      fecha_recogida: "Pickup date",
      hora_recogida: "Pickup time",
      correo_electronico: "Email address",
      email_para_ticket_compra: "E-mail is required to receive the ticket purchase",
      datos: "Data",
      metodo: "Method",
      n_tarjeta: "Card number",
      fecha_caducidad: "Expiration date",
      realizar_pedido: "MAKE AN ORDER",
      pedido_realizado_correcto: "!ORDER PERFORMED CORRECTLY!",
      llegara_ticket_a_email: "Remember that you will receive the purchase ticket in your email",
      volver_tienda: "Return to the store",
      fallo_pago: "¡FAILURE TO PAY THE ORDER!",
      fallo_revisa: "Something went wrong, please check all the fields and try again",
      faltan_datos: "Complete all data to continue.",
      cp_no_valido: "Sorry in this zip code we do not deliver.",
      comprando: "Completing the payment process. Please do not close this window.",
      error_pago: "We were unable to complete the payment. Try again.",
      error_source: "Invalid card. Try again.",
      error_customer: "We are unable to complete the order. Check the email, phone and other information, try again.",
      card_info: "Complete card information.",
      horarios: "Schedule",
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      open: 'Open',
      closed: 'Closed',
      privacy_policy_footer: 'Privacy policy',
      terms_conditions: 'Terms and Conditions',
      menu_allergens: "This is a menu that contains allergens, please contact the waiter for more information",
      lang_espanol: "Spanish",
      lang_catala: "Catalan",
      lang_ingles: "English",
      lang_aleman: "German",
      lang_frances: "French",
      lang_italiano: "Italian",
      select_lang: "Select language",
      cargando_horario: "",
      notification_push_new_order_title: "New order",
      notification_push_new_order_desc_1: "You have a new TakeAway / On-Site Delivery order",
      notification_push_call_min_stock_title: "Warning: stock limit!",
      notification_push_call_min_stock_desc_1: "You have reached the item's stock limit ",
      notification_push_call_min_stock_desc_2: " go through it so that you don't run out of stock",
      see_schedule: "Horaire"
    },
    de: {
      name: "Name",
      phone: "Phone",
      email: "E-mail",
      date: "Date",
      time: "Time",
      adults: "Adults",
      children: "Children",
      comments: "Comments",
      privacy_policy: "I accept the Privacy Policy",
      send_reservation: "Send",
      select_another_date: "No availability for this date. Select another date.",
      reservation_sent_correctly: "SENT CORRECTLY",
      accordance_email: "You will receive the confirmation in your email.",
      not_able_check_reservation: "We have not been able to check the availability of the reservation.",
      complete_all_camps: "Required fields *",
      contact: "Contact",
      reservation: "Reservations",
      menu: "Menus",
      daily_menus: "Daily menus",
      allergens: "Allergens",
      included: "included",
      pva_incluido: "Bread, wine and water included",
      pva_no_incluido: "Bread, wine and water NOT included",
      cafe_incluido: "Coffee included",
      cafe_no_incluido: "Coffee NOT included",
      refrescos_incluidos: "Soft drinks included",
      refrescos_no_incluidos: "Soft drinks NOT included",
      racion: "Portion",
      tapa: "Bar snack",
      zonas_reparto: 'Delivery areas',
      tu_pedido: 'YOUR ORDER',
      total: 'TOTAL',
      confirmar_pagar: "CONFIRM AND PAY",
      listo_para_pedir: "READY TO ORDER",
      anadir: "ADD",
      cerrar: "Close",
      alcohol: "Alcohol",
      huevo: "Egg *",
      altramueces: "Lupin bean",
      crustaceos: "Crustaceans *",
      frutos_secos: "Nuts *",
      gluten: "Gluten *",
      setas: "Mushrooms",
      apio: "Celery",
      pescado: "Fish *",
      lactosa: "Lactose *",
      soja: "Soy *",
      picante_suave: "Soft spicy",
      picante: "Spicy",
      muy_picante: "Very spicy",
      maiz: "Corn",
      cacahuetes: "Peanuts",
      vegetariano: "Vegetarian",
      vegano: "Vegan",
      dioxido_azufre_sulfitos: "Sulfur dioxide and sulphites *",
      ecologico: "Ecological",
      sesamo: "Sesame",
      kosher: "Kosher",
      mostaza: "Mustard",
      cerdo: "Pork",
      moluscos: "Mollusks",
      cafeina: "Caffeine",
      halal: "Halal",
      infantil: "Children",
      km0: "KM0",
      mediterraneo: "Mediterranean",
      comida_rapida: "Fast food",
      recomendado: "Recommended",
      sin_azucar: "Sugar-free",
      estevia: "Stevia",
      poner_cantidad_correcta: "Put correct amount",
      la_seccion: "The section",
      es_obligatoria: "it is required.",
      cantidad: "Quantity",
      cancelar: "Cancel",
      guardar: "Add",
      codigos_postales: "Zip codes",
      subtotal: "SUBTOTAL",
      elements: "ELEMENTS",
      edit: "Edit",
      payment: "Payment",
      cvv: "CVV",
      sending: "Sending",
      campos_obligatorios: "* required fields",
      mesa: "Table",
      recoger: "Pick up",
      a_domicilio: "Delivery",
      datos_contacto: "Contact information",
      direccion: "Address",
      n_piso_puerta: "Nº/Floor/Door",
      c_p: "Z.P.",
      localidad: "Location",
      pais: "Country",
      fecha_entrega: "Delivery date",
      hora_entrega: "Delivery time",
      comentario: "Comment",
      acepto_privacidad_condiciones: "I accept the privacy policy and conditions of use",
      volver_al_pedido: "back to order",
      siguiente: "Next",
      fecha_recogida: "Pickup date",
      hora_recogida: "Pickup time",
      correo_electronico: "Email address",
      email_para_ticket_compra: "E-mail is required to receive the ticket purchase",
      datos: "Data",
      metodo: "Method",
      n_tarjeta: "Card number",
      fecha_caducidad: "Expiration date",
      realizar_pedido: "MAKE AN ORDER",
      pedido_realizado_correcto: "!ORDER PERFORMED CORRECTLY!",
      llegara_ticket_a_email: "Remember that you will receive the purchase ticket in your email",
      volver_tienda: "Return to the store",
      fallo_pago: "¡FAILURE TO PAY THE ORDER!",
      fallo_revisa: "Something went wrong, please check all the fields and try again",
      faltan_datos: "Complete all data to continue.",
      cp_no_valido: "Sorry in this zip code we do not deliver.",
      comprando: "Completing the payment process. Please do not close this window.",
      error_pago: "We were unable to complete the payment. Try again.",
      error_source: "Invalid card. Try again.",
      error_customer: "We are unable to complete the order. Check the email, phone and other information, try again.",
      card_info: "Complete card information.",
      horarios: "Schedule",
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      open: 'Open',
      closed: 'Closed',
      privacy_policy_footer: 'Privacy policy',
      terms_conditions: 'Terms and Conditions',
      menu_allergens: "This is a menu that contains allergens, please contact the waiter for more information",
      lang_espanol: "Spanish",
      lang_catala: "Catalan",
      lang_ingles: "English",
      lang_aleman: "German",
      lang_frances: "French",
      lang_italiano: "Italian",
      select_lang: "Select language",
      cargando_horario: "",
      notification_push_new_order_title: "New order",
      notification_push_new_order_desc_1: "You have a new TakeAway / On-Site Delivery order",
      notification_push_call_min_stock_title: "Warning: stock limit!",
      notification_push_call_min_stock_desc_1: "You have reached the item's stock limit ",
      notification_push_call_min_stock_desc_2: " go through it so that you don't run out of stock",
      see_schedule: "Schedule"      
    }
  }
}
