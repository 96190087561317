import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GlobalsService } from '../../globals.service';
import { ModalAllergensComponent } from '../../modal-allergens/modal-allergens.component';
import { OrdersalesService } from '../../ordersales.service';

@Component({
  selector: 'app-select-elements-combo',
  templateUrl: './select-elements-combo.component.html',
  styleUrls: ['./select-elements-combo.component.scss'],
})
export class SelectElementsComboComponent implements OnInit {

  @Input() combo: any;
  @Input() lang: any;
  public units: number = 1;

  constructor(private modalController: ModalController,
    private toastCtrl: ToastController,
    private globals: GlobalsService,
    public orderSalesService: OrdersalesService) {

  }

  ngOnInit() {
    this.combo.price_with_extras = this.combo.price;
  }

  cancel() {
    this.modalController.dismiss();
  }

  onChange(event) {
    this.units = event.target.value;
  }

  add() {
    this.units++;
  }

  rest() {
    if (this.units > 1) {
      this.units--;
    }
  }

  save() {
    //miramos si podemos insertar el combo (cumple con lo obligatorio?)
    var _ret = this.checkComboOKToInsert();
    var n_combos = 0;
    //caso todo ok 
    if (_ret) {
      //
      n_combos = this.countCombos();
      if (this.units && this.units > 0) {
        this.insertComboInComandero(n_combos);
        //funcion que ordena/junta dishes/prods
        this.orderSalesService.orderCesta();
        //
        this.cleanMenu();
        this.modalController.dismiss();
        //
      } else {
        alert(this.globals.trans_lang[this.globals.lang]['poner_cantidad_correcta']);
      }
    }
  }


  cleanMenu() {
    for (let section of this.globals.objectToArray(this.combo.sections)) {
      if (section.selected) {
        section.selected = '';
      }
      //
      for (let dish of this.globals.objectToArray(section.dish)) {
        if (dish.active) {
          dish.active = false;
        }
      }
    }
  }

  checkComboOKToInsert() {
    var _ret = true;
    //
    for (let section of this.globals.objectToArray(this.combo.sections)) {
      //
      var dish_required = false;
      var _price_with_extras = 0;
      for (let dish of this.globals.objectToArray(section.dish)) {
        //miramos si hay algun plato activo (caso activo quiere decir que cumplimos con que hay 1 seleccionado en el apartado)
        if (dish.active == true) {
          dish_required = true;
        }
        //miramos los extras
        if (section.combo_cost_extra == true && (dish.active == true || (section.selected != undefined && section.selected != ''))) {
          _price_with_extras += dish.price_combo_extra;
        }

      }
      this.combo.price_with_extras += _price_with_extras;
      //
      if (section.combo_required == true && (dish_required == true || (section.selected != undefined && section.selected != ''))) {
      } else if (section.combo_required == undefined || section.combo_required == false) {
      } else {
        this.launchToast(section.name);
        _ret = false;
      }
    }

    return _ret;
  }

  countCombos() {
    var n_combos = 0;
    for (let section of this.globals.objectToArray(this.globals.order_tmp)) {
      for (let element of this.globals.objectToArray(section.list)) {
        if (element.type == 'combo' || element.type == 'menu') {
          n_combos++;
        }
      }
    }
    return n_combos;
  }

  insertComboInComandero(n_combos) {
    //insert combo
    this.orderSalesService.insertTable(this.combo.title_es, this.combo.price_with_extras, false, true, 'combo', this.combo.UID, 'Menu', this.combo.UID, n_combos, this.units, 'combo', this.combo.tags);
    //insert elements of combo
    for (let section of this.globals.objectToArray(this.combo.sections)) {
      //caso select
      if (section.selected != undefined && section.selected != '') {
        var s_e = section.selected.split("-");
        var i_section = s_e[0];
        var i_element = s_e[1];
        if (this.combo.sections[i_section].dish[i_element].type == 'dish') {
          this.orderSalesService.insertTable(this.globals.nameLangSelectMenuDish(this.lang, this.combo.sections[i_section].dish[i_element]), 0, false, true, this.combo.sections[i_section].dish[i_element].type, this.combo.sections[i_section].dish[i_element].uid, section.name, this.combo.UID, n_combos, this.units, 'combo', this.combo.sections[i_section].dish[i_element].tags);
        } else if (this.combo.sections[i_section].dish[i_element].type == 'product') {
          this.orderSalesService.insertTable(this.globals.nameProductLangSelect(this.lang, this.combo.sections[i_section].dish[i_element]), 0, false, true, this.combo.sections[i_section].dish[i_element].type, this.combo.sections[i_section].dish[i_element].uid, section.name, this.combo.UID, n_combos, this.units, 'combo', this.combo.sections[i_section].dish[i_element].tags);
        }
      }
      for (let element of this.globals.objectToArray(section.dish)) {
        //caso checkbox
        if (element.active == true) {
          if (element.type == 'dish') {
            this.orderSalesService.insertTable(this.globals.nameLangSelectMenuDish(this.lang, element), 0, false, true, element.type, element.uid, section.name, this.combo.UID, n_combos, this.units, 'combo', element.tags);
          } else if (element.type == 'product') {
            this.orderSalesService.insertTable(this.globals.nameProductLangSelect(this.lang, element), 0, false, true, element.type, element.uid, section.name, this.combo.UID, n_combos, this.units, 'combo', element.tags);
          }
        }
      }
    }
  }

  /***/
  async launchToast(name) {
    let toast = await this.toastCtrl.create({
      message: this.globals.trans_lang[this.globals.lang]['la_seccion'] + ' ' + name + ' ' + this.globals.trans_lang[this.globals.lang]['es_obligatoria'],
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  onChangeElement(value) {
    var s_e = value.split("-");
    var i_section = s_e[0];
    var i_element = s_e[1];
    //Iniciamos los arrays
    if (this.combo.sections[i_section].allergens_selected == undefined) {
      this.combo.sections[i_section].allergens_selected = [];
      this.combo.sections[i_section].allergens_elements_count = [];
    }
    //Si no es un checkout, añadimos los alergenos en el array
    if (this.combo.sections[i_section].selected != undefined && this.combo.sections[i_section].selected != '') {
      this.combo.sections[i_section].allergens_selected = this.combo.sections[i_section].dish[i_element].allergens;
    }
    //Si es un checkout active
    if (this.combo.sections[i_section].dish[i_element].active) {
      var index;
      //Recorremos los alergenos del elemento
      this.globals.objectToArray(this.combo.sections[i_section].dish[i_element].allergens).forEach(element => {
        //Buscamos si el alergeno esta en el array de alergenos activos
        index = this.combo.sections[i_section].allergens_selected.findIndex(x => x == element);
        //Si no esta, lo añadimos y sumamos 1 al count de alergenos activos
        if (index < 0) {
          this.combo.sections[i_section].allergens_selected = this.combo.sections[i_section].allergens_selected.concat(element);
          this.combo.sections[i_section].allergens_elements_count = this.combo.sections[i_section].allergens_elements_count.concat(1);
        } else {
          //Si existe, sumamos 1 al count de alergenos activos
          this.combo.sections[i_section].allergens_elements_count[index] = this.combo.sections[i_section].allergens_elements_count[index] + 1;
        }
      });
    } else {
      //Si es un checkout desactivado
      var index;
      //Recorremos los alergenos del elemento
      this.globals.objectToArray(this.combo.sections[i_section].dish[i_element].allergens).forEach(element => {
        index = this.combo.sections[i_section].allergens_selected.findIndex(x => x == element);
        //Si el count de alergenos activos es 1, se elimina
        if (this.combo.sections[i_section].allergens_elements_count[index] == 1) {
          delete this.combo.sections[i_section].allergens_elements_count[index];
          delete this.combo.sections[i_section].allergens_selected[index];
        } else {
          //Si el count de alergenos activos es mayor a  1, se resta 1
          this.combo.sections[i_section].allergens_elements_count[index] = this.combo.sections[i_section].allergens_elements_count[index] - 1;
        }
      });
    }
  }

  async modalAllergens() {
    const modal = await this.modalController.create({
      component: ModalAllergensComponent,
      componentProps: {
        'lang': this.globals.lang,
      }
    });
    return await modal.present();
  }

}
