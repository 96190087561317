import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalsService } from '../../globals.service';
import { ModalSelectElementsComponent } from '../../modal-select-elements/modal-select-elements.component';
import { OrdersalesService } from '../../ordersales.service';

@Component({
  selector: 'app-carta-template-ossa',
  templateUrl: './carta-template-ossa.component.html',
  styleUrls: ['./carta-template-ossa.component.scss'],
})
export class CartaTemplateOssaComponent implements OnInit {
  
  @Input() section;
  @Input() carta;

  constructor(public globals: GlobalsService,
    private modalController: ModalController,
    public orderSalesService: OrdersalesService,) { }

  ngOnInit() {}

  async addElement(element, origin, section_name) {
    var title = this.globals.returnNameByLang(element);
    if (element.type == 'menu') {
      title = element.name_es;
      origin = 'combo';
      element = this.globals.all_menus.find(e => e.UID == element.uid);
    }



    const modal = await this.modalController.create({
      component: ModalSelectElementsComponent,
      componentProps: {
        'element': element,
        'title': title,
        'origin': origin,
        'section_name': section_name,
        'lang': this.globals.lang,
      }
    });

    modal.onDidDismiss().then(v => {
      this.orderSalesService.calculateTotal();
      this.orderSalesService.calculateTotalWithoutTax();
      this.showOrder();
    });
    return await modal.present();
  }

  showOrder() {
    let order = document.getElementById('order_cesta');
    if (this.globals.order_cesta.length > 0) {
      order.style.display = 'block';
      order.classList.add("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");

    } else {
      order.style.display = 'none';
      order.classList.remove("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");
    }
  }

  bgImg(img) {
    let _img = this.globals.getElementImg(img);
    let styles = {
      'background-image': 'url("' + _img + '")',
      'background-size': 'cover',
      'background-position': 'center'
    }
    return styles;
  }
}
