import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalsService } from '../../globals.service';
import { ModalAllergensComponent } from '../../modal-allergens/modal-allergens.component';
import { ModalSelectElementsComponent } from '../../modal-select-elements/modal-select-elements.component';
import { OrdersalesService } from '../../ordersales.service';

@Component({
  selector: 'app-menu-template-audi-a1',
  templateUrl: './menu-template-audi-a1.component.html',
  styleUrls: ['./menu-template-audi-a1.component.scss'],
})
export class MenuTemplateAudiA1Component implements OnInit {

  @Input() menu;
  
  constructor(public globals: GlobalsService,
    private modalController: ModalController,
    public orderSalesService: OrdersalesService,) { }

  ngOnInit() {}

  async modalAllergens() {
    const modal = await this.modalController.create({
      component: ModalAllergensComponent,
      componentProps: {
        'lang': this.globals.lang,
      }
    });
    return await modal.present();
  }

  async addMenuCombo(menu) {
    var origin = 'menu';
    if (menu.style == 'combo') {
      origin = 'combo';
    }

    const modal = await this.modalController.create({
      component: ModalSelectElementsComponent,
      componentProps: {
        'element': menu,
        'title': menu.title_es,
        'origin': origin,
        'lang': this.globals.lang,
      }
    });

    modal.onDidDismiss().then(v => {
      this.orderSalesService.calculateTotal();
      this.orderSalesService.calculateTotalWithoutTax();
      this.showOrder();
    });
    return await modal.present();
  }

  showOrder() {
    let order = document.getElementById('order_cesta');
    if (this.globals.order_cesta.length > 0) {
      order.style.display = 'block';
      order.classList.add("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");

    } else {
      order.style.display = 'none';
      order.classList.remove("d-none", "d-xl-block", "d-block", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-none");
    }
  }

}
