import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: ':domini',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: ':domini/:lang',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full'
  },
 /* {
    path: ':domini/:lang/order',
    loadChildren: () => import('./payments/order-info/order-info.module').then(m => m.OrderInfoPageModule)
  },
  {
    path: ':domini/:lang/horary',
    loadChildren: () => import('./payments/order-info-horary/order-info-horary.module').then(m => m.OrderInfoHoraryPageModule)
  },
  {
    path: 'modal-order',
    loadChildren: () => import('./modal-order/modal-order.module').then(m => m.ModalOrderPageModule)
  },
  {
    path: 'homev2/test/test/test',
    loadChildren: () => import('./homev2/homev2.module').then( m => m.Homev2PageModule)
  },*/
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
