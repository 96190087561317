import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(public globals: GlobalsService,
    public afs: AngularFirestore,) { }

  /**********************************************************/
  /*                        FUNCIONES                       */
  /**********************************************************/

  //notification push
  notificationPushOneUser(notification) {
    this.afs.collection('notifications').add({
      'UID_RESTAURANT': notification.UID_RESTAURANT,
      'UID_USER': notification.UID_USER,
      'title': notification.title,
      'description': notification.description,
      'date': new Date()
    });
  }

  //notificacion que envia a todos los usuarios del restaurante
  notificationPushAllRestaurant(notification) {
    for (let user of this.globals.users_array) {
      notification.UID_USER = user.uid_user_auth;
      this.notificationPushOneUser(notification);
    }
  }
}
