import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireAuth } from '@angular/fire/auth';

import { Environment } from '@ionic-native/google-maps'; 
import { GlobalsService } from './globals.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AngularFireAuth
  ) {
    this.initializeApp();
  }

 async initializeApp() {
    this.auth.signInWithEmailAndPassword('developer@mycheftool.com', '12345678').catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
    });


    this.platform.ready().then(() => {
      this.splashScreen.show();
      //Google maps
      /*Environment.setEnv({
        'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyBlPzO-Z9DKVlJsdCLFSAjUwpsoiXzq3v8',
        'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyBlPzO-Z9DKVlJsdCLFSAjUwpsoiXzq3v8'
      });*/

     
      this.statusBar.styleDefault();
    });
  }
}
