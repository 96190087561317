import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-description-lang',
  templateUrl: './description-lang.component.html',
  styleUrls: ['./description-lang.component.scss'],
})
export class DescriptionLangComponent implements OnInit {

  @Input() lang;
  @Input() value_es: any;
  @Input() value_ca;
  @Input() value_en;
  @Input() value_de;
  @Input() value_it;
  @Input() value_fr;

  constructor() { }

  ngOnInit() {}

}
