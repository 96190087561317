import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GlobalsService } from 'src/app/globals.service';
import { OrdersalesService } from 'src/app/ordersales.service';
import { ModalAllergensComponent } from '../../modal-allergens/modal-allergens.component';

@Component({
  selector: 'app-select-elements-menu',
  templateUrl: './select-elements-menu.component.html',
  styleUrls: ['./select-elements-menu.component.scss'],
})
export class SelectElementsMenuComponent implements OnInit {

  @Input() menu: any;
  @Input() lang: any;
  public units: number = 1;

  constructor(private modalController: ModalController,
    private toastCtrl: ToastController,
    private globals: GlobalsService,
    public orderSalesService: OrdersalesService) { }

  ngOnInit() { }

  cancel() {
    this.modalController.dismiss();
  }

  onChange(event) {
    this.units = event.target.value;
  }

  add() {
    this.units++;
  }

  rest() {
    if (this.units > 1) {
      this.units--;
    }
  }

  save() {
    var _ret = this.checkMenuOKToInsert();
    var n_combos = 0;
    if (_ret) {
      n_combos = this.countCombos();
      //
      if (this.units && this.units > 0) {
        this.insertMenuInComandero(n_combos);
        //funcion que ordena/junta dishes/prods
        this.orderSalesService.orderCesta();
        //
        this.cleanMenu();
        this.modalController.dismiss();
        //
      } else {
        alert(this.globals.trans_lang[this.globals.lang]['poner_cantidad_correcta']);
      }

    }
  }

  cleanMenu() {
    for (let section of this.globals.objectToArray(this.menu.sections)) {
      if (section.selected) {
        section.selected = '';
      }
    }
  }

  checkMenuOKToInsert() {
    var _ret = true;
    //
    for (let section of this.globals.objectToArray(this.menu.sections)) {
      //
      if (section.selected != undefined && section.selected != '') {
      } else {
        this.launchToast(section.name);
        _ret = false;
      }
    }
    return _ret;
  }

  countCombos() {
    var n_combos = 0;
    for (let section of this.globals.objectToArray(this.globals.order_tmp)) {
      for (let element of this.globals.objectToArray(section.list)) {
        if (element.type == 'combo' || element.type == 'menu') {
          n_combos++;
        }
      }
    }
    return n_combos;
  }

  insertMenuInComandero(n_combos) {
    //insert menu
    this.orderSalesService.insertTable(this.menu.title_es, this.menu.price, false, true, 'menu', this.menu.UID, 'Menu', this.menu.UID, n_combos, this.units, 'menu', []);
    //insert elements of menu
    for (let section of this.globals.objectToArray(this.menu.sections)) {
      //NOS FALTA EL TIPO DE ELEMENTO EN MENU NO SALE EL TIPO HAY QEU REVISAR
      if (section.selected != undefined && section.selected != '') {
        var s_e = section.selected.split("-");
        var i_section = s_e[0];
        var i_element = s_e[1];
        if (this.menu.sections[i_section].dish[i_element].type == 'dish') {
          this.orderSalesService.insertTable(this.globals.nameLangSelectMenuDish(this.lang, this.menu.sections[i_section].dish[i_element]), 0, false, false, this.menu.sections[i_section].dish[i_element].type, this.menu.sections[i_section].dish[i_element].uid, section.name, this.menu.UID, n_combos, this.units, 'element', this.menu.sections[i_section].dish[i_element].tags);
        } else if (this.menu.sections[i_section].dish[i_element].type == 'product') {
          this.orderSalesService.insertTable(this.globals.nameProductLangSelect(this.lang, this.menu.sections[i_section].dish[i_element]), 0, false, false, this.menu.sections[i_section].dish[i_element].type, this.menu.sections[i_section].dish[i_element].uid, section.name, this.menu.UID, n_combos, this.units, 'element', this.menu.sections[i_section].dish[i_element].tags);
        }
      }
    }
  }

  /***/
  async launchToast(name) {
    let toast = await this.toastCtrl.create({
      message: this.globals.trans_lang[this.globals.lang]['la_seccion'] + ' ' + name + ' ' + this.globals.trans_lang[this.globals.lang]['es_obligatoria'],
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  onChangeElement(value) {
    var s_e = value.split("-");
    var i_section = s_e[0];
    var i_element = s_e[1];
    //Guardamos en la seccion que allergenos tiene el plato
    this.menu.sections[i_section].allergens_selected = this.menu.sections[i_section].dish[i_element].allergens;
  }

  async modalAllergens() {
    const modal = await this.modalController.create({
      component: ModalAllergensComponent,
      componentProps: {
        'lang': this.globals.lang,
      }
    });
    return await modal.present();
  }
}
