import { NgModule} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';


import { AllergensComponent } from './allergens/allergens.component';
import { DescriptionLangComponent } from './description-lang/description-lang.component';
import { SelectElementsDishProductComponent } from './select-elements-dish-product/select-elements-dish-product.component';
import { ModalSelectElementsComponent } from '../modal-select-elements/modal-select-elements.component';
import { SelectElementsComboComponent } from './select-elements-combo/select-elements-combo.component';
import { SelectElementsMenuComponent } from './select-elements-menu/select-elements-menu.component';
import { FormsModule } from '@angular/forms';
import { MenuTemplateChallengerComponent } from './menu-template-challenger/menu-template-challenger.component';
import { MenuTemplateCamaroComponent } from './menu-template-camaro/menu-template-camaro.component';
import { CartaTemplateCotaComponent } from './carta-template-cota/carta-template-cota.component';
import { CartaTemplateGladiusComponent } from './carta-template-gladius/carta-template-gladius.component';
import { CartaTemplateTmaxComponent } from './carta-template-tmax/carta-template-tmax.component';
import { CartaTemplateAeroxComponent } from './carta-template-aerox/carta-template-aerox.component';
import { CartaTemplateScoopyComponent } from './carta-template-scoopy/carta-template-scoopy.component';
import { CartaTemplateBultacoComponent } from './carta-template-bultaco/carta-template-bultaco.component';
import { CartaTemplateVarianComponent } from './carta-template-varian/carta-template-varian.component';
import { MenuTemplateFirebirdComponent } from './menu-template-firebird/menu-template-firebird.component';
import { CartaTemplateJogComponent } from './carta-template-jog/carta-template-jog.component';
import { CartaTemplateVespinoComponent } from './carta-template-vespino/carta-template-vespino.component';
import { SelectLangComponent } from './select-lang/select-lang.component';
import { MenuTemplateMultiplaComponent } from './menu-template-multipla/menu-template-multipla.component';
import { ComboTemplateConorComponent } from './combo-template-conor/combo-template-conor.component';
import { ComboTemplateBhComponent } from './combo-template-bh/combo-template-bh.component';
import { CartaTemplateCota25Component } from './carta-template-cota25/carta-template-cota25.component';
import { CartaTemplateTyphoonComponent } from './carta-template-typhoon/carta-template-typhoon.component';
import { CartaTemplateOssaComponent } from './carta-template-ossa/carta-template-ossa.component';
import { MenuTemplateTiguanComponent } from './menu-template-tiguan/menu-template-tiguan.component';
import { CartaTemplatePuchComponent } from './carta-template-puch/carta-template-puch.component';
import { CartaTemplateVespaComponent } from './carta-template-vespa/carta-template-vespa.component';
import { MenuTemplateFordFocusComponent } from './menu-template-ford-focus/menu-template-ford-focus.component';
import { CartaTemplateRd350Component } from './carta-template-rd350/carta-template-rd350.component';
import { MenuTemplateAudiComponent } from './menu-template-audi/menu-template-audi.component';
import { MenuTemplateAudiA1Component } from './menu-template-audi-a1/menu-template-audi-a1.component';
import { MenuTemplateMercedesComponent } from './menu-template-mercedes/menu-template-mercedes.component';
import { CartaTemplateP200Component } from './carta-template-p200/carta-template-p200.component';

@NgModule({
  declarations: [
    AllergensComponent,
    DescriptionLangComponent,
    ModalSelectElementsComponent,
    SelectElementsDishProductComponent,
    SelectElementsComboComponent,
    SelectElementsMenuComponent,
    MenuTemplateChallengerComponent,
    MenuTemplateCamaroComponent,
    CartaTemplateCotaComponent,
    CartaTemplateGladiusComponent,
    CartaTemplateTmaxComponent,
    CartaTemplateAeroxComponent,
    CartaTemplateScoopyComponent,
    CartaTemplateBultacoComponent,
    CartaTemplateVarianComponent,
    MenuTemplateFirebirdComponent,
    CartaTemplateJogComponent,
    CartaTemplateVespinoComponent,
    SelectLangComponent,
    MenuTemplateMultiplaComponent,
    ComboTemplateConorComponent,
    ComboTemplateBhComponent,
    CartaTemplateCota25Component,
    CartaTemplateTyphoonComponent,
    CartaTemplateOssaComponent,
    MenuTemplateTiguanComponent,
    CartaTemplatePuchComponent,
    CartaTemplateVespaComponent,
    MenuTemplateFordFocusComponent,
    CartaTemplateRd350Component,
    MenuTemplateAudiComponent,
    MenuTemplateAudiA1Component,
    MenuTemplateMercedesComponent,
    CartaTemplateP200Component

  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule
  ],
  exports: [
    AllergensComponent,
    DescriptionLangComponent,
    ModalSelectElementsComponent,
    SelectElementsDishProductComponent,
    SelectElementsComboComponent,
    SelectElementsMenuComponent,
    MenuTemplateChallengerComponent,
    MenuTemplateCamaroComponent,
    CartaTemplateCotaComponent,
    CartaTemplateGladiusComponent,
    CartaTemplateTmaxComponent,
    CartaTemplateAeroxComponent,
    CartaTemplateScoopyComponent,
    CartaTemplateBultacoComponent,
    CartaTemplateVarianComponent,
    MenuTemplateFirebirdComponent,
    CartaTemplateJogComponent,
    CartaTemplateVespinoComponent,
    SelectLangComponent,
    MenuTemplateMultiplaComponent,
    ComboTemplateConorComponent,
    ComboTemplateBhComponent,
    CartaTemplateCota25Component,
    CartaTemplateTyphoonComponent,
    CartaTemplateOssaComponent,
    MenuTemplateTiguanComponent,
    CartaTemplatePuchComponent,
    CartaTemplateVespaComponent,
    MenuTemplateFordFocusComponent,
    CartaTemplateRd350Component,
    MenuTemplateAudiComponent,
    MenuTemplateAudiA1Component,
    MenuTemplateMercedesComponent,
    CartaTemplateP200Component
  ]
})
export class ComponentsModule { }
