import { Component, OnInit, Input } from '@angular/core';
import { GlobalsService } from 'src/app/globals.service';
import { ModalController } from '@ionic/angular';
import { OrdersalesService } from 'src/app/ordersales.service';

@Component({
  selector: 'app-select-elements-dish-product',
  templateUrl: './select-elements-dish-product.component.html',
  styleUrls: ['./select-elements-dish-product.component.scss'],
})
export class SelectElementsDishProductComponent implements OnInit {

  @Input() element: any;
  @Input() lang: any;
  @Input() section_name: any;
  public units: number = 1;
  public price_type: string = 'price_ration';

  constructor(private globals: GlobalsService,
    private modalController: ModalController,
    public orderSalesService: OrdersalesService) { }

  ngOnInit() { }

  onChange(event) {
    this.units = event.target.value;
  }

  add() {
    this.units++;
  }

  rest() {
    if (this.units > 1) {
      this.units--;
    }
  }

  selectTypePrice(type) {
    this.price_type = type;
  }

  addElement() {
    if (this.units && this.units > 0) {
      if (this.element.type == 'dish') {
        this.orderSalesService.insertTable(this.returnName(this.globals.nameLangSelect(this.lang, this.element)), this.returnPrice(), false, true, this.element.type, this.element.uid, this.section_name, null, null, this.units, 'element', this.element.tags);
      } else if (this.element.type == 'product') {
        this.orderSalesService.insertTable(this.returnName(this.globals.nameLangSelect(this.lang, this.element)), this.returnPrice(), false, true, this.element.type, this.element.uid, this.section_name, null, null, this.units, 'element', this.element.tags);
      }
      //funcion que ordena/junta dishes/prods
      this.orderSalesService.orderCesta();
      this.modalController.dismiss();
    } else {
      alert(this.globals.trans_lang[this.globals.lang]['poner_cantidad_correcta']);
    }
  }

  returnPrice() {
    if (this.price_type == 'price_ration') {
      return this.element.price;
    } else if (this.price_type == 'price_half') {
      return this.element.price_half;
    } else if (this.price_type == 'price_tapa') {
      return this.element.price_tapa;
    } else {
      return this.element.price;
    }
  }

  returnName(name) {
    if (this.price_type == 'price_ration') {
      return name;
    } else if (this.price_type == 'price_half') {
      return name + " M.";
    } else if (this.price_type == 'price_tapa') {
      return name + " P.";
    } else {
      return name;
    }
  }

}
